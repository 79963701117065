import React, { useState } from 'react';


// import './style.css';
import { Button, Form, Input } from 'antd';
import { SubmitButton } from '../../../../components';


interface Step1Props {
  handleNextStep: () => void;
}

export const StepOne: React.FC<Step1Props> = ({ handleNextStep }) => {
  const [form] = Form.useForm();

  const [isLoading, setisLoading] = useState(false);




  const onFinish = (values: any) => {
    console.log('Success:', values);
      handleNextStep();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmailForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {}
  };



  const next = () => {
    return (
      <div>
        <span>Save And Continue</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.56224 13.4062H17.7466L14.3646 9.11719C14.2685 8.99531 14.3552 8.8125 14.5122 8.8125H16.031C16.2607 8.8125 16.4787 8.91797 16.6193 9.09844L20.463 13.9734C20.8497 14.4656 20.5005 15.1875 19.8747 15.1875H3.56224C3.45912 15.1875 3.37474 15.1031 3.37474 15V13.5938C3.37474 13.4906 3.45912 13.4062 3.56224 13.4062Z"
            fill="#FEF8E6"
          />
        </svg>
      </div>
    );
  };
  return (
 
    
   
        <div className="frame-4">
          <div className="group-wrapper">
            <div className="group-3">
              <div className="group-4">
                <div className="text-wrapper-3">Corporate Information</div>
              </div>
            </div>
          </div>
          <div className="frame-5">
            <div className="frame-6">
              <div className="frame-7">
                <Form
                  form={form}
                  name="signInForm"
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="horizontal"
                >
                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Full Company Legal Name
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="companyName"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your company name!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter company Name'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="frame-9">
                        <div className="text-wrapper-4">Doing Business As</div>
                        <div>
                          <div className="text-wrapper-5">
                            {' '}
                            <Form.Item
                              name="BusinessAs"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your Business As!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Business As'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Registration/Incorporation Number
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="IncorporationNumber"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Incorporation Number!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Incorporation Number'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Date of Incorporation
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            {' '}
                            <Form.Item
                              name="DateIncorporation"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Date of Incorporation!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Date of Incorporation'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">Tax ID Number</div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="TaxID"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your Tax ID Number!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Tax ID Number'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="frame-9">
                        <div className="text-wrapper-4">Registered Address</div>
                        <div>
                          <div className="text-wrapper-5">
                            {' '}
                            <Form.Item
                              name="RegisteredAddress"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Registered Address!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Registered Address'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Country of Incorporation
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="CountryofIncorporation"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Country of Incorporation!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Country of Incorporation'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Principal Place of Business - Country
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            {' '}
                            <Form.Item
                              name="PrincipalPlaceofBusinessCountry"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Principal Place of Business - Country!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={
                                  'Enter Principal Place of Business - Country'
                                }
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">Company Legal Type</div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="CompanyLegalType"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Company Legal Type!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Company Legal Type'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="frame-9">
                        <div className="text-wrapper-4">
                          Is the Company Publicly Listed?
                        </div>
                        <div>
                          <div className="text-wrapper-5">
                            {' '}
                            <Form.Item
                              name="PubliclyListed"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input if your Company Publicly Listed !',
                                },
                              ]}
                            >
                              <Input
                                placeholder={
                                  'Enter if your Company Publicly Listed'
                                }
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-8">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="text-wrapper-4">Company Website</div>
                        <div>
                          <div className="text-wrapper-5">
                            <Form.Item
                              name="Website"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your Website!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Website'}
                                className="frame-10"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Form.Item>
                    <SubmitButton
                      form={form}
                      submitProp={next()}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
  
  );
};
