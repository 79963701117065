import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { logo, rightLayout2 } from "../../../../assets/images";
import {
  WhicHome,
  checkIsDeveloperPortal,
  checkIsHome,
} from "../../../../utils/RouterHelpers";
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [navText, setNav] = useState("");
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isHome = checkIsHome(pathname, window.location.hash);
  const isDeveloperPortal = checkIsDeveloperPortal(
    pathname,
    window.location.hash
  );

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;

      if (window.pageYOffset > 80) {
        setNav("navbar-fixed");
      } else {
        setNav("");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const redirectLogin = () => {
    navigate("/developer-portal-login");
  };

  const redirectSignup = () => {
    navigate("/signup");
  };


    const redirectVerifySignup = () => {
    navigate("/verify-signup");
  };
  

  const WhereToNavigate = () => {
    return "/";
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSidebar(!sidebar);
  };


  const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
  <button className="nav-btn-link-3" onClick={redirectVerifySignup}>
                   Verify Sign Up
                </button> 
    ),
  },
  {
    key: '2',
    label: (
   <button className="nav-btn-link-4" onClick={redirectSignup}>
                  New Sign Up
                </button> 
    ),
  },

];

  return (
    <div>
      <div className="d-flex justify-content-between position-absolute w-100 ">
        <div className="imgLeft"></div>
        <div className="imgRight2">
          <img src={rightLayout2} className="w-100 h-100" alt="" />
        </div>
      </div>

      <nav className={`nav ${navText}`} id="nav">
        <div className="nav-center">
          {/* Nav header */}
          <div className="nav-header">
            <div>
              <img src={logo} className="nav-logo" alt="nav logo" />
            </div>
            <div>
              <button
                id="nav-btn"
                className={`hamburger-button ${menuOpen ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </button>
            </div>
          </div>

          {isHome && (
            <>
                  <div className="nav-link-container">
            <div className="nav-links">
              <span>
                <HashLink to={"/home/#sendMoney"}>Send Money</HashLink>
              </span>

              <span>
                <HashLink to={"/home/#howitworks"}>How It Works</HashLink>
              </span>

              <span style={{display: "flex", justifyContent: "center"}}>
                <HashLink to={"/home/#features"}>Features</HashLink>
              </span>

      
              <span>
                <HashLink to={"/home/#mission"}>Our Mission</HashLink>
              </span>

              <span>
                <HashLink to={"/home/#contact"}>Get In Touch</HashLink>
              </span>
            </div>
          </div>

              <span className="nav-button-container align-items-center">
                <button className="nav-btn-link" onClick={redirectLogin}>
                  Sign In
                </button>
                {/* <button className="nav-btn-link-2" onClick={redirectSignup}>
                   Sign Up
                </button> */}

                    <Dropdown menu={{ items }} placement="bottomLeft" arrow>
      <Button> Sign Up</Button>
    </Dropdown>

              </span>
            </>
          )}

          {isDeveloperPortal && (
            <>
              <div className="nav-link-container">
                <div className="nav-links">
                  <span>
                    {/* <HashLink to={"/#sendMoney"}>Send Money</HashLink> */}
                  </span>
                  <span></span>
                  <span>
                    <HashLink to={"/#sendMoney"}>API Documentation</HashLink>
                  </span>

                  <span>
                    <HashLink to={"/developer-portal/#contact"}>Support</HashLink>
                  </span>
                </div>
              </div>

              <span className="nav-button-container align-items-center">
                <button className="nav-btn-link" onClick={redirectLogin}>
                  Sign In
                </button>
                <button className="nav-btn-link-2" onClick={redirectSignup}>
                   Sign Up
                </button>
              </span>
            </>
          )}
        </div>
        {/* siderbar */}
        <aside
          className={`sidebar ${sidebar ? "show-sidebar" : ""}`}
          id="sidebar"
        >
          <div>
            {isHome && (
              <>
                <ul className="sidebar-menu">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#about"}>Send Money</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#howitworks"}>How It Works</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#features"}>Features</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#mission"}>Our Mission</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#contactUs"}>Get In Touch</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <Link to={"/signin"}>Sign In</Link>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <Link to={"/signup"}> Sign Up</Link>
                  </li>
                </ul>
              </>
            )}

            {isDeveloperPortal && (
              <>
                <ul className="sidebar-menu">
                  <li onClick={() => setSidebar(!sidebar)}>
                    <HashLink to={"/#mission"}>API Documentation</HashLink>
                  </li>

                  <li onClick={() => setSidebar(!sidebar)}>
                    <HashLink to={"/#contact"}>Support</HashLink>
                  </li>

                  <li onClick={() => setSidebar(!sidebar)}>
                    <Link to={"/signin"}>Sign In</Link>
                  </li>

                  <li onClick={() => setSidebar(!sidebar)}>
                    <Link to={"/signup"}> Sign Up</Link>
                  </li>
                </ul>
              </>
            )}
          </div>
        </aside>
      </nav>
    </div>
  );
};

export { Header };
