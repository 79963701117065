import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useRequestSignin } from '../useAuth';
import * as ciap from 'gcip-iap';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ErrorHandler } from '../../../services/ErrorHandler';
import { SuccessToast, ErrorToast } from '../../../services/toast';
import { SubmitButton } from '../../../components';
import { backIcon } from '../../../assets/images';
import { useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';

const DeveloperPortalLogin: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isEmailFormInvalid, setIsEmailFormInvalid] = useState(true);
  const [isPasswordFormInvalid, setIsPasswordFormInvalid] = useState(true);
  const [userEmail, setUserEmail] = useState();
  const [userCred, setSuserCred] = useState();
  const { data, error, mutate, isLoading } = useRequestSignin();
  const [config, setConfig] = useState<any>(null);
  const [ciapInstance, setCiapInstance] = useState<any>();
  const [progressBarTimer, setProgressBarTimer] = useState<any>();
  const [redirecting, setRedirect] = useState(false);
  const [d, setD] = useState<any>();
  const [rememberMe, setRememberMe] = useState(false);

  const storedObjectString = localStorage.getItem('rememberMeToken');
  const retrievedObject: any = JSON.parse(storedObjectString!);
  console.log(retrievedObject?.email);

  const onFinish = (values: any) => {
    console.log('Success:', values);

    mutate(
      {
        email: values.email,
        password: values.password,
      },
      {
        onSuccess: async (res: any) => {
          if (rememberMe) {
            // Store authentication information (e.g., token) securely, using a library like secure-store
            var dataTp = JSON.stringify({
              email: values.email,
              password: values.password,
            });
            localStorage.setItem('rememberMeToken', dataTp);
          }

          sessionStorage.setItem('email', values.email)

          if (res?.data?.status_code === 200) {
            if (res?.data?.message !== '') {
              navigate('/otpVerify-login');
            }

            if (res?.data?.data?.token) {
              sessionStorage.setItem('token', res?.data?.data?.token);

              sessionStorage.setItem(
                'authData',
                JSON.stringify(res?.data?.data)
              );

              navigate('/developer-portal/home');
            }
          }
        },
        onError: (res: any) => {
          console.log(res.response.data.errors['0'], 'error popo');

          ErrorToast(res.response.data.errors['0']);
          setRedirect(false);
        },
        onSettled: (res: any) => {},
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmailForm = async () => {
    try {
      await form.validateFields();
      setIsEmailFormInvalid(false);
    } catch (error) {
      setIsEmailFormInvalid(true);
    }
  };

  const validatePasswordForm = async () => {
    try {
      await form.validateFields();
      setIsPasswordFormInvalid(false);
    } catch (error) {
      setIsPasswordFormInvalid(true);
    }
  };

  const next = () => {
    return (
      <div>
        <span>Next</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.56224 13.4062H17.7466L14.3646 9.11719C14.2685 8.99531 14.3552 8.8125 14.5122 8.8125H16.031C16.2607 8.8125 16.4787 8.91797 16.6193 9.09844L20.463 13.9734C20.8497 14.4656 20.5005 15.1875 19.8747 15.1875H3.56224C3.45912 15.1875 3.37474 15.1031 3.37474 15V13.5938C3.37474 13.4906 3.45912 13.4062 3.56224 13.4062Z"
            fill="#FEF8E6"
          />
        </svg>
      </div>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="main-container p-3 mt-5">
        <div className={'loginForm'}>
          <div className="d-flex align-items-center justify-content-between">
            <h1>Sign In</h1>
            <img
              src={backIcon}
              alt=""
              className="w-30px h-30px"
              onClick={() => goBack()}
            />
          </div>

          <Form
            form={form}
            name="signInForm"
            initialValues={{
              email: retrievedObject?.email,
              password: retrievedObject?.password,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'The input is not a valid E-mail!' },
              ]}
            >
              <Input
                placeholder={'Enter Email'}
                prefix={
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5776 6.54175C13.5776 6.54175 10.9025 9.75227 8.94598 9.75227C6.99025 9.75227 4.28516 6.54175 4.28516 6.54175"
                      stroke="#A4A4A6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 8.99991C1 3.3015 2.98413 1.40259 8.93651 1.40259C14.8889 1.40259 16.873 3.3015 16.873 8.99991C16.873 14.6975 14.8889 16.5972 8.93651 16.5972C2.98413 16.5972 1 14.6975 1 8.99991Z"
                      stroke="#A4A4A6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                onBlur={validateEmailForm}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}
            >
              <Input.Password
                placeholder={'Enter Password'}
                prefix={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7258 7.83597V6.04513C13.7 3.94596 11.9767 2.26596 9.87833 2.2918C7.8225 2.31763 6.16 3.97263 6.125 6.02847V7.83597"
                      stroke="#A4A4A6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.92521 11.7966V13.6475"
                      stroke="#A4A4A6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.92532 7.35327C5.13783 7.35327 3.54199 8.65994 3.54199 12.5791C3.54199 16.4991 5.13783 17.8058 9.92532 17.8058C14.7128 17.8058 16.3095 16.4991 16.3095 12.5791C16.3095 8.65994 14.7128 7.35327 9.92532 7.35327Z"
                      stroke="#A4A4A6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                onBlur={validatePasswordForm}
              />
            </Form.Item>

            <div className="forgot-con">
              <div className={'forgetPassword'}>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  className="m-4"
                />
                <span>Remember Me:</span>
              </div>

              <div className={'forgetPassword m-4'}>
                <Link to={'/forget-password'}>Forgot Password?</Link>
              </div>
            </div>

            <Form.Item>
              <SubmitButton
                form={form}
                isLoading={isLoading}
                submitProp={next()}
              />
            </Form.Item>
          </Form>

          <div className={'loginFooter'}>
            <div className={'signUp'}>
              Don’t have an account? <Link to={'/signup'}>Sign Up</Link>
            </div>

            <div>
              By continuing you agree to our{' '}
              <Link to={'/terms'} style={{ textDecoration: 'none' }}>
                <span>Terms & Conditions</span>
              </Link>{' '}
              and{' '}
              <Link to={'/privacy'} style={{ textDecoration: 'none' }}>
                {' '}
                <span>Privacy Policy.</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeveloperPortalLogin;
