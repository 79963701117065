import React, { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Error404 } from './component/Error404';
import { DashboardLayout, Layout } from '../components';
import Login from '../pages/Auth/login';
import ForgetPassword from '../pages/Auth/forget-password';
import NewPassword from '../pages/Auth/new-password';
import Register from '../pages/Auth/register';
import OtpVerification from '../pages/Auth/pinSection/OtpVerification';
import TransactionPin from '../pages/Auth/pinSection/TransactionPin';
import Dashboard from '../pages/Dashboard';
import Developer from '../pages/Developer';
import FAQ from '../pages/Faq';
import Privacy from '../pages/privacy';
import Terms from '../pages/Terms';
import VerifyRegister from '../pages/Auth/verify-register';
import { DeveloperPortal } from '../pages/Developer-portal';
import { VerifyAccount } from '../pages/Developer-portal/Verify';
import { StepSix } from '../pages/Developer-portal/Verify/step/StepSix';
import DeveloperPortalLogin from '../pages/Auth/login/DeveloperPortalLogin';
import { AppLayout } from "../components/src/layout/AppLayout";
import { AgentLayout } from '../components/src/layout/AgentLayout';
import PreFund from '../pages/Developer-portal/PreFund/Index'
import OtpVerificationLogin from '../pages/Auth/pinSection/OtpVerificationLogin';
import Transactions from '../pages/Developer-portal/Transaction';




const AppRouter: FC = () => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/home" element={<Dashboard />} />
        <Route path="/developer-portal" element={<Developer />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Route>
      <Route element={<Layout />}>
        {/* <Route path="/" element={<Navigate to="/signin" />} /> */}
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<Login />} />
        <Route
          path="/developer-portal-login"
          element={<DeveloperPortalLogin />}
        />
        <Route path="/signup" element={<Register />} />
        <Route path="/verify-signup" element={<VerifyRegister />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/otpVerify" element={<OtpVerification />} />
        <Route path="/otpVerify-login" element={<OtpVerificationLogin />} />
        <Route path="/createPin" element={<TransactionPin />} />
      </Route>
      <Route path="/developer-portal/verify" element={<VerifyAccount />} />
      <Route path="/developer-portal/doucment-upload" element={<StepSix />} />
      <Route path="*" element={<Error404 />} />



            <Route element={<AppLayout />}>
            <Route element={<AgentLayout />}>
          <Route path="/developer-portal/home" element={<DeveloperPortal />} />
            <Route path="/developer-portal/prefund" element={<PreFund />} />
            <Route path="/developer-portal/transactions" element={<Transactions />} />

     
          </Route>
        
        </Route>
    </Routes>

    
  );
};

export { AppRouter };
