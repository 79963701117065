import React, { useState } from 'react';

// import './style.css';
import { Button, Form, Input } from 'antd';
import { SubmitButton } from '../../../../components';
import TextArea from 'antd/es/input/TextArea';

interface Step1Props {
  handleNextStep: () => void;
}

export const StepFour: React.FC<Step1Props> = ({ handleNextStep }) => {
  const [form] = Form.useForm();

  const [isLoading, setisLoading] = useState(false);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    handleNextStep();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmailForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {}
  };

  const next = () => {
    return (
      <div>
        <span>Save And Continue</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.56224 13.4062H17.7466L14.3646 9.11719C14.2685 8.99531 14.3552 8.8125 14.5122 8.8125H16.031C16.2607 8.8125 16.4787 8.91797 16.6193 9.09844L20.463 13.9734C20.8497 14.4656 20.5005 15.1875 19.8747 15.1875H3.56224C3.45912 15.1875 3.37474 15.1031 3.37474 15V13.5938C3.37474 13.4906 3.45912 13.4062 3.56224 13.4062Z"
            fill="#FEF8E6"
          />
        </svg>
      </div>
    );
  };
  return (
    <>
      <Form
        form={form}
        name="signInForm"
        initialValues={{
          email: '',
          password: '',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <div className="stepFour">
          <div className="stepFour-wrapper">
            <div className="div">
              <div className="div-2">
                <div className="div-2">
                  <div className="group-wrapper">
                    <div className="group-2">
                      <div className="group-3">
                        <div className="text-wrapper-2">
                          Corporate Governance dd
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="please-note-that">
                  <span className="span">Please note that fields with (</span>
                  <span className="span">) asterisk is required</span>
                </p>
              </div>
              <div className="div-3">
                <div className="stepFour-wrapper-2">
                  <div className="stepFour-wrapper-3">
                    <div className="div-4">
                      <div className="div-5">
                        <p className="has-the-board-of">
                          <span className="text-wrapper-4">
                            Has the Board of Directors established an AML/CFT
                            program?
                          </span>
                          <span className="span">&nbsp;</span>
                        </p>
                        <div className="stepFour-wrapper-4">
                          <div>
                            <div className="div-6">
                              <Form.Item
                                name="AMLCFTprogram"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'As the Board of Directors established an AML/CFT program?',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={
                                    'As the Board of Directors established an AML/CFT program?'
                                  }
                                  className="stepFour-wrapper-5"
                                  onBlur={validateEmailForm}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-5">
                        <p className="has-the-institution">
                          <span className="text-wrapper-4">
                            Has the institution appointed an AML/CFT compliance
                            officer?
                          </span>
                          <span className="span">&nbsp;</span>
                          <span className="text-wrapper-3">
                            {' '}
                            <br />
                          </span>
                          <span className="text-wrapper-4">
                            If Yes, please provide the name, Phone no. and email{' '}
                          </span>
                        </p>
                        <div className="div-7">
                          <div className="stepFour-wrapper-6">
                            <div className="stepFour-wrapper-7">
                              <div className="div-wrapper-2">
                                <div className="text-wrapper-5">Name</div>
                              </div>
                            </div>
                          </div>
                          <div className="stepFour-wrapper-6">
                            <div className="stepFour-wrapper-7">
                              <div className="div-wrapper-2">
                                <div className="text-wrapper-5">Email</div>
                              </div>
                            </div>
                          </div>
                          <div className="stepFour-wrapper-6">
                            <div className="stepFour-wrapper-7">
                              <div className="div-wrapper-2">
                                <div className="text-wrapper-5">
                                  Phone Number
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-5">
                        <p className="p">
                          <span className="text-wrapper-4">
                            Does your institution have appropriate and adequate
                            administrative sanctions in place for non-compliance
                            with AML obligations?
                          </span>
                        </p>
                        <div className="stepFour-wrapper-4">
                          <div className="stepFour-wrapper-5">
                            <div className="div-6">
                              <div className="text-wrapper-5">(yes Or No)</div>
                              <img
                                className="vuesax-outline-arrow"
                                src="https://c.animaapp.com/9AdyNHB9/img/vuesax-outline-arrow-down-2.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-5">
                        <p className="p">
                          <span className="text-wrapper-4">
                            Please provide the name of the Regulator that
                            oversees the AML/CFT compliance of the Institution.
                          </span>
                        </p>
                        <div className="stepFour-wrapper-4">
                          <div className="stepFour-wrapper-5">
                            <div className="div-6">
                              <div className="text-wrapper-5">Name</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-8">
                  <div className="div-wrapper-3">
                    <div className="text-wrapper-6">Back</div>
                  </div>
                  <Form.Item>
                    <SubmitButton form={form} submitProp={next()} />
                  </Form.Item>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
