import React, { useEffect, useState } from 'react';
import { Button, Modal, Switch, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '../EmptyState';
import type { MenuProps } from 'antd';
import { message } from 'antd';
import { useRequestGetAllPreFund } from '../../Auth/useAuth';

const Transactions: React.FC = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);


  const { data, error, mutate, isLoading } = useRequestGetAllPreFund();

  
  

  useEffect(() => {
mutate()
  }, [])

  const handleChange = (checked: any) => {
    setIsChecked(checked);
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    message.info(`Click on item ${key}`);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Default',
      key: '1',
    },
    {
      label: 'Admin',
      key: '2',
    },
    {
      label: 'Support',
      key: '3',
    },
    {
      label: 'Compliance',
      key: '3',
    },
  ];

  const getBackgroundStyle = (text: any) => {
    switch (text.toLowerCase()) {
      case 'awaiting verification':
        return {
          display: 'flex',
          width: '156px',
          height: '34px',
          padding: '2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-secondary-text-50, #EAEAEA)',
          
        };
      case 'verified':
        return {
          display: 'flex',
          width: '146px',
          height: '34px',
          padding: '2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',

          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-secondary-text-50, #EAFFE0)',
        };
    }
  };

  const getBackgroundStyle2 = (text: any) => {
    switch (text.toLowerCase()) {
      case 'not started':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #E6FBFF)',
        };
      case 'not required':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #F1E9FD)',
        };
      // Add cases for other text values with corresponding styles
      case 'approved':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #EAFFE0)',
        };

      case 'is progress':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFF7E7)',
        };

      case 'rejected':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFDDDE)',
        };

      case 'in progress':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFF7E7)',
        };
    }
  };

  const dataSource: any = [
    
  ];

  const columns: any[] = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'TransactionDate',
      key: 'TransactionDate',
    },
    {
      title: 'Transaction Reference',
      dataIndex: 'TransactionReference',
      key: 'TransactionReference',
    },
 
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
    },

       {
      title: 'Narration',
      dataIndex: 'Narration',
      key: 'Narration',
    },
       
            {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },

       
   
       
  
          

  ];



    
    for (let i = 0; i < data?.data?.data?.length; i++) {


  const userData = data?.data.data[i];
  
  // Extracting email and roles_rights
  const email = userData?.email;
  const roles_rights = userData?.roles_rights || [];

  // Extracting the first role (if available)
  const firstRole = roles_rights?.length > 0 ? roles_rights[0] : null;
      

  // Creating the row object
  const row = {
      email: userData?.email,
      TransactionDate: userData?.fund_date,
      TransactionReference: userData?.pre_fund_bank_name,
      AccountNumber: userData?.nostro_bank_account_number,
      Amount: userData?.amount,
      Date: userData?.fund_date,
  };
        dataSource.push(row);

    }

  const redirectCreateuser = () => {};

  return (
    <>
   <div className="Transactions">
      <div className="div">
        <div className="div-2">
          <div className="Transactions-wrapper">
            <div className="div-wrapper">
              <div className="div-3">
                <div className="Transactions-wrapper-2">
                  <div className="Transactions-wrapper-3">
                    <div className="div-4">
                      <div className="Transactions-wrapper-4">
                        <div className="Transactions-wrapper-5">
                          <div className="div-5">
                            <div className="div-wrapper-2"><div className="text-wrapper">All Transactions</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="line" src="https://c.animaapp.com/pj1juw4J/img/line-24.svg" />
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="transaction-card">
              <div className="div-7">
                <div className="div-8">
                  <div className="text-wrapper-2">All Transactions</div>
                  <div className="text-wrapper-3">$230,588</div>
                </div>
                <div className="div-9">
                  <div className="div-10">
                    <div className="text-wrapper-4">+5%</div>
                    <div className="text-wrapper-5">Since last week</div>
                  </div>
                  <div className="div-10">
                    <div className="text-wrapper-6">Volume</div>
                    <div className="text-wrapper-6">1,298</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction-card">
              <div className="div-7">
                <div className="div-8">
                  <div className="text-wrapper-2">Successful</div>
                  <div className="text-wrapper-3">$170,588</div>
                </div>
                <div className="div-9">
                  <div className="div-10">
                    <div className="text-wrapper-4">+5%</div>
                    <div className="text-wrapper-5">Since last week</div>
                  </div>
                  <div className="div-10">
                    <div className="text-wrapper-6">Volume</div>
                    <div className="text-wrapper-6">1,298</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction-card">
              <div className="div-7">
                <div className="div-8">
                  <div className="text-wrapper-2">Pending</div>
                  <div className="text-wrapper-3">$1,908</div>
                </div>
                <div className="div-9">
                  <div className="div-10">
                    <div className="text-wrapper-4">+5%</div>
                    <div className="text-wrapper-5">Since last week</div>
                  </div>
                  <div className="div-10">
                    <div className="text-wrapper-6">Volume</div>
                    <div className="text-wrapper-6">1,298</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Transactions-wrapper-6">
          <div className="div-11">
            <div className="text-wrapper-7">Filter</div>
            <div className="div-4">
              <div className="div-12">
                <div className="div-4">
                  <div className="Transactions-wrapper-7">
                    <div className="div-13">
                      <div className="text-wrapper-8">Search Email</div>
                      <img className="img" src="https://c.animaapp.com/pj1juw4J/img/vuesax-outline-search-normal-1.svg" />
                    </div>
                  </div>
                  <div className="Transactions-wrapper-7">
                    <div className="div-13">
                      <div className="text-wrapper-8">Search by ID</div>
                      <img className="img" src="https://c.animaapp.com/pj1juw4J/img/vuesax-outline-search-normal-1.svg" />
                    </div>
                  </div>
                  <div className="div-14">
                    <div className="text-wrapper-9">From</div>
                    <div className="div-15">
                      <div className="text-wrapper-10">DD-MM-YYYY</div>
                      <img className="img" src="https://c.animaapp.com/pj1juw4J/img/vuesax-outline-calendar-1.svg" />
                    </div>
                  </div>
                  <div className="div-14">
                    <div className="text-wrapper-11">To</div>
                    <div className="div-15">
                      <div className="text-wrapper-10">DD-MM-YYYY</div>
                      <img className="img" src="https://c.animaapp.com/pj1juw4J/img/vuesax-outline-calendar-1.svg" />
                    </div>
                  </div>
                </div>
                <div className="div-16">
                  <div className="div-14">
                    <div className="text-wrapper-11">Display Status</div>
                    <div className="div-15">
                      <div className="text-wrapper-10">All</div>
                      <img className="img" src="https://c.animaapp.com/pj1juw4J/img/vuesax-outline-arrow-down.svg" />
                    </div>
                  </div>
                  <div className="component-wrapper">
                    <div className="component">
                      <div className="div-17"><div className="next">Apply Filter</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="TABLE-transactions">
           {/* <div className={'superAgentTable'}> */}
          {/* {dataAgents?.data ? <>  <Table dataSource={[]} columns={columns} locale={{ emptyText: EmptyState }} />;</> : 
                    <><Table dataSource={dataAgents?.data} columns={columns} locale={{ emptyText: EmptyState }}/>;</>
                    } */}
          <Table
            dataSource={dataSource}
            columns={columns}
            locale={{ emptyText: EmptyState }}
          />
        </div>
      </div>
    {/* </div> */}

    </>
  );
};

export default Transactions;
