export const passwordLockSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.7257 7.83609V6.04525C13.6999 3.94609 11.9765 2.26609 9.87821 2.29192C7.82238 2.31775 6.15988 3.97275 6.12488 6.02859V7.83609"
      stroke="#A4A4A6"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.92521 11.7968V13.6476"
      stroke="#A4A4A6"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.9252 7.35352C5.1377 7.35352 3.54187 8.66018 3.54187 12.5793C3.54187 16.4993 5.1377 17.806 9.9252 17.806C14.7127 17.806 16.3094 16.4993 16.3094 12.5793C16.3094 8.66018 14.7127 7.35352 9.9252 7.35352Z"
      stroke="#A4A4A6"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const appleSvg = (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="21"
      cy="21"
      r="20.6111"
      stroke="#E3E8F1"
      stroke-width="0.777778"
    />
    <path
      d="M26.5172 21.5555C26.5078 19.957 27.232 18.7523 28.6945 17.8641C27.8766 16.6922 26.6391 16.0477 25.0078 15.9234C23.4633 15.8016 21.7734 16.8234 21.1547 16.8234C20.5008 16.8234 19.0055 15.9656 17.8289 15.9656C15.4008 16.0031 12.8203 17.9016 12.8203 21.7641C12.8203 22.9055 13.0289 24.0844 13.4461 25.2984C14.0039 26.8969 16.0148 30.8133 18.1125 30.75C19.2094 30.7242 19.9852 29.9719 21.4125 29.9719C22.7977 29.9719 23.5148 30.75 24.7383 30.75C26.8547 30.7195 28.6734 27.1594 29.2031 25.5563C26.3648 24.218 26.5172 21.6375 26.5172 21.5555ZM24.0539 14.407C25.2422 12.9961 25.1344 11.7117 25.0992 11.25C24.0492 11.3109 22.8352 11.9648 22.1437 12.7688C21.382 13.6313 20.9344 14.6977 21.0305 15.9C22.1648 15.9867 23.2008 15.4031 24.0539 14.407Z"
      fill="black"
    />
  </svg>
);

export const googleSvg = (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="21"
      cy="21"
      r="20.6111"
      stroke="#E3E8F1"
      stroke-width="0.777778"
    />
    <path
      d="M31.501 21.2332C31.501 20.3699 31.4296 19.7399 31.2748 19.0865H21.2153V22.9832H27.12C27.001 23.9515 26.3582 25.4099 24.9296 26.3898L24.9096 26.5203L28.0902 28.935L28.3106 28.9565C30.3343 27.1249 31.501 24.4298 31.501 21.2332Z"
      fill="#4285F4"
    />
    <path
      d="M21.2147 31.5C24.1075 31.5 26.5361 30.5666 28.3099 28.9566L24.929 26.3898C24.0242 27.0082 22.8099 27.4399 21.2147 27.4399C18.3814 27.4399 15.9767 25.6082 15.1195 23.0765L14.9938 23.087L11.6866 25.5954L11.6433 25.7132C13.4052 29.1432 17.0242 31.5 21.2147 31.5Z"
      fill="#34A853"
    />
    <path
      d="M15.12 23.0767C14.8938 22.4234 14.7629 21.7233 14.7629 21C14.7629 20.2767 14.8938 19.5767 15.1081 18.9234L15.1021 18.7842L11.7534 16.2356L11.6438 16.2867C10.9176 17.71 10.501 19.3084 10.501 21C10.501 22.6917 10.9176 24.29 11.6438 25.7133L15.12 23.0767Z"
      fill="#FBBC05"
    />
    <path
      d="M21.2148 14.56C23.2267 14.56 24.5838 15.4116 25.3576 16.1233L28.3814 13.23C26.5243 11.5383 24.1076 10.5 21.2148 10.5C17.0243 10.5 13.4052 12.8566 11.6433 16.2866L15.1076 18.9233C15.9767 16.3917 18.3815 14.56 21.2148 14.56Z"
      fill="#EB4335"
    />
  </svg>
);
