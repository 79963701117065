import React, { useEffect, useState } from 'react';
import { Pin } from '../../../components';
import { useRequestSignup, useRequestValidateOtp } from '../useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const OtpVerification: React.FC = () => {
  const { data, mutate, isLoading } = useRequestValidateOtp();
    const { data: resenOtpData, error: resenOtpError, mutate: resenOtpMutate, isLoading:resenOtpLoading } = useRequestSignup();

  const navigate = useNavigate();
  const location = useLocation();

  var email = sessionStorage.getItem('email');
  var locationSave = sessionStorage.getItem('location');

 // Initialize the state to hold the remaining time and countdown date
  const [countDownDate, setCountDownDate] = useState<any>(null);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Function to calculate the remaining time
  function calculateTimeRemaining() {
    if (!countDownDate) {
      return { seconds: 0, expired: true };
    }

    const now = new Date().getTime();
    const distance = Math.max(countDownDate - now, 0); // Ensure the remaining time is non-negative

    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { seconds, expired: distance === 0 };
  }

  // Function to start the countdown
  const startCountdown = (duration: any) => {
    const newCountDownDate = new Date().getTime() + duration * 1000;
    setCountDownDate(newCountDownDate);
  };

  // Update the countdown every 1 second using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [countDownDate]); // Add countDownDate to the dependencies to restart the countdown when it changes


  const onResendOtp = () => {
  // Retrieve the JSON string from sessionStorage
let retrievedDataString: any = sessionStorage.getItem('register');

// Parse the JSON string back to an object
  let retrievedData = JSON.parse(retrievedDataString);
  
startCountdown(120)


        resenOtpMutate(
      retrievedData,
      {
        onSuccess: (res: any) => {

          // navigate("/otpVerify");
        },
        onSettled: () => {},
      }
    );


  }
  
  

  const onVerify = (otp: any) => {
    var payload = {
      email,
      otp,
    };

    console.log(location.pathname);

    mutate(
      payload,
      {
        onSuccess: async (res: any) => {
          console.log(res.data.data.token);



          if (
            res?.data?.response_code === 200 ||
            res?.data?.status_code === 200
          ) {
            sessionStorage.setItem("token", res.data.data.token);

            if (locationSave === '/verify-signup') {
              navigate('/new-password', { replace: true });
            } else {
              navigate('/developer-portal-login', { replace: true });
            }
          }
        },
      }
    );
  };

  return (
    <Pin
      proceed={(res) => onVerify(res)}
      header={'OTP Verification'}
      message={'Enter your OTP'}
      canResend={true}
      resendFn={() => onResendOtp()}
      timeRemaining={timeRemaining}

    />
  );
};

export default OtpVerification;
