import React, { useEffect, useState } from 'react';
import {

  stopShop1,
  stopShop2,
  stopShop3
} from '../../assets/images';
import { useNavigate } from 'react-router-dom';

const Developer: React.FC = () => {
  const features = [
    'Simple and fast remittance service',
    'Multi-currency bank account to support our global customers',
    'Mobile digital wallet for fast and reliable payment for good and services',
  ];
    const navigate = useNavigate();
   const redirectSignup = () => {
    navigate("/signup");
   };
  
    React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    }, []);
  
  
  return (
    <div className="body-dashboard">
      {/* section one */}
      <div
        className=" d-flex justify-content-space between title-body"
        id="sendMoney"
      >
        <div className="title-body-text-2">
          <span className="header-title text-aling-header ">
            we believe in the power of <br />
            <span className="text-yellow">
              connectivity <span style={{ color: '#fff' }}>and</span>{' '}
              data-driven
            </span>
            <br />
            <span>Solution</span>
          </span>

          <div className="feature-desc-container mt-9">
            <span className="feature-description">
              Our robust and user-friendly API (Application Programming
              Interface) is designed to simplify the way your applications
              interact with the digital world. Whether you're a developer, or a
              business owner, our API is here to empower your projects and open
              up a world of possibilities.
            </span>
          </div>

          <span className="d-flex align-items-center itm-cen">
            <button className="started-btn"onClick={redirectSignup} >GET STARTED</button>
          </span>
        </div>
      </div>

      {/* section three */}
      <div className="features-container-2 min-h-500px" id="features">
        <div className="d-flex flex-column align-items-center">
          <div>
            <span className="title">One Stop Shop</span>
          </div>

          <div className="features"></div>

          <div className="d-flex mb-20">
            <div className="d-none d-lg-block fileBroImage">
              <img src={stopShop1} className="w-100 h-100" alt="" />
            </div>

            <div className="benefits-list-container">
              <div className="middle-sm ">
                <span className="text-din">
                  One API Integration,
                  <br /> Multiple financial institution
                  <br /> in Nigeria
                </span>
              </div>

              <div className="mt-9">
                <span className="middle-sm-txt">
                  We have designed our API-as-a-service to make global money
                  transfer very convenient and inexpensive by removing all the
                  restrictions and bottlenecks associated with International
                  remittance. With our strategic alliances with payment service
                  providers, identity verification and fraud prevention service
                  providers, we are able to provide a safe, secure and reliable
                  way to send, receive and spend money across the world.
                </span>
              </div>

              <div>
                <span className="d-flex align-items-center features-btn-container middle-sm ">
                  <button className="started-btn" onClick={redirectSignup}>GET STARTED</button>
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex mt-20">
            <div className="benefits-list-container">
              <div className="middle-sm ">
                <span className="text-din">
                  Security and Redundancy
                  <br /> Management
                </span>
              </div>

              <div className="mt-9">
                <span className="middle-sm-txt">
                  Never loose your instruction throughout the lifecycle,
                  providing the needed redundancy required with banking
                  connectivity issues that can occur.
                </span>
              </div>

              <div>
                <span className="d-flex align-items-center features-btn-container middle-sm ">
                  <button className="started-btn" onClick={redirectSignup}>GET STARTED</button>
                </span>
              </div>
            </div>

            <div className="d-none d-lg-block fileBroImage">
              <img src={stopShop2} className="w-100 h-100" alt="" />
            </div>
          </div>

                <div className="d-flex mb-20">
            <div className="d-none d-lg-block fileBroImage">
              <img src={stopShop3} className="w-100 h-100" alt="" />
            </div>

            <div className="benefits-list-container">
              <div className="middle-sm ">
                <span className="text-din">
                 Compliance
                </span>
              </div>

              <div className="mt-9">
                <span className="middle-sm-txt">
                 Next generation compliance for the instruction, sender, and receiver.
                </span>
              </div>

              <div>
                <span className="d-flex align-items-center features-btn-container middle-sm ">
                  <button className="started-btn" onClick={redirectSignup}>GET STARTED</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developer;
