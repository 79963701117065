import {emptyState} from "../../assets/images";
export const EmptyState = () => {
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <img src={emptyState} alt="Empty State" style={{ width: 'auto', height: '200px' }} />
                <p>No data available</p>
            </div>
        </>
    );
};
