import React, { useEffect, useState } from 'react';
import { Button, Modal, Switch, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '../EmptyState';
import type { MenuProps } from 'antd';
import { message } from 'antd';
import { useRequestGetAllPreFund } from '../../Auth/useAuth';

const Prefund: React.FC = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);


  const { data, error, mutate, isLoading } = useRequestGetAllPreFund();

  
  

  useEffect(() => {
mutate()
  }, [])

  const handleChange = (checked: any) => {
    setIsChecked(checked);
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    message.info(`Click on item ${key}`);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Default',
      key: '1',
    },
    {
      label: 'Admin',
      key: '2',
    },
    {
      label: 'Support',
      key: '3',
    },
    {
      label: 'Compliance',
      key: '3',
    },
  ];

  const getBackgroundStyle = (text: any) => {
    switch (text.toLowerCase()) {
      case 'awaiting verification':
        return {
          display: 'flex',
          width: '156px',
          height: '34px',
          padding: '2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-secondary-text-50, #EAEAEA)',
          
        };
      case 'verified':
        return {
          display: 'flex',
          width: '146px',
          height: '34px',
          padding: '2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',

          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-secondary-text-50, #EAFFE0)',
        };
    }
  };

  const getBackgroundStyle2 = (text: any) => {
    switch (text.toLowerCase()) {
      case 'not started':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #E6FBFF)',
        };
      case 'not required':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #F1E9FD)',
        };
      // Add cases for other text values with corresponding styles
      case 'approved':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #EAFFE0)',
        };

      case 'is progress':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFF7E7)',
        };

      case 'rejected':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFDDDE)',
        };

      case 'in progress':
        return {
          display: 'flex',
          width: '120px',
          height: '34px',
          padding: ' 2px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
          flexShrink: '0',
          borderRadius: '4px',
          background: 'var(--foundation-blue-b-50, #FFF7E7)',
        };
    }
  };

  const dataSource: any = [
    
  ];

  const columns: any[] = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Reference ID',
      dataIndex: 'ReferenceID',
      key: 'ReferenceID',
    },
    {
      title: 'Pre-fund Bank',
      dataIndex: 'Bank',
      key: 'Bank',
    },

    {
      title: 'Account Number',
      dataIndex: 'AccountNumber',
      key: 'AccountNumber',
    },

 

    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
    },

       {
      title: 'Fund Date',
      dataIndex: 'Date',
      key: 'Date',
    },


  ];



    
    for (let i = 0; i < data?.data?.data?.length; i++) {


  const userData = data?.data.data[i];
  
  // Extracting email and roles_rights
  const email = userData?.email;
  const roles_rights = userData?.roles_rights || [];

  // Extracting the first role (if available)
  const firstRole = roles_rights?.length > 0 ? roles_rights[0] : null;
      

  // Creating the row object
  const row = {
      email: userData?.email,
      ReferenceID: userData?.reference_id,
      Bank: userData?.pre_fund_bank_name,
      AccountNumber: userData?.nostro_bank_account_number,
      Amount: userData?.amount,
      Date: userData?.fund_date,
  };
        dataSource.push(row);

    }

  const redirectCreateuser = () => {};

  return (
    <>
      <div>
        <span className="all-user-text"> All Pre-Funds</span>
      </div>

      <div className={'agentListSection'}>
        <div className={'tableHeader'}>
          <div>Agent List</div>

          <div>
            <Button className={'filter'} ghost>
              Filter
            </Button>
            <Button className={'exportToCsv'} ghost>
              Export as CSV
            </Button>
          </div>
        </div>

        <div className={'superAgentTable'}>
          {/* {dataAgents?.data ? <>  <Table dataSource={[]} columns={columns} locale={{ emptyText: EmptyState }} />;</> : 
                    <><Table dataSource={dataAgents?.data} columns={columns} locale={{ emptyText: EmptyState }}/>;</>
                    } */}
          <Table
            dataSource={dataSource}
            columns={columns}
            locale={{ emptyText: EmptyState }}
          />
        </div>
      </div>
    </>
  );
};

export default Prefund;
