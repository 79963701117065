import type { FormInstance } from "antd";
import { Button, Form } from "antd";
import React from "react";

const SubmitButton = ({
  form,
  submitProp,
  isLoading,
}: {
  form: FormInstance;
  submitProp?: any;
  isLoading?: any;
}) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Button htmlType="submit" disabled={!submittable}>
      {isLoading ? (
        <span className="indicator-progress d-flex justify-content-center  align-items-center">
          <span className="d-none d-md-block">Please wait... </span>
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        submitProp || <span>Create Account</span>
      )}
    </Button>
  );
};
export { SubmitButton };
