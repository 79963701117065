import React, { useState } from 'react';

import './style.css';
import {  Form } from 'antd';
import { StepOne } from './step/StepOne';
import { StepTwo } from './step/StepTwo';
import { StepThree } from './step/StepThree';
import { StepFour } from './step/StepFour';
import { StepFive } from './step/StepFive';
import { StepSix } from './step/StepSix';
import { useNavigate } from 'react-router-dom';

export const VerifyAccount = (): JSX.Element => {

      const navigate = useNavigate();

  const [step, setStep] = useState(1);
  
    const finalStep = 5;

  const handleNextStep = () => {
    if (step === finalStep) {
       navigate('/developer-portal/doucment-upload');

    } else {
      setStep(step + 1);
      console.log(step);
    }
  };




  return (
    <div className="questionaire">
      <div className="navbar">
        <div className="frame">
          <img
            className="img"
            src="https://c.animaapp.com/uXeCcQLH/img/frame-19.svg"
          />
          <div className="frame-wrapper">
            <div className="div-wrapper">
              <div className="div">
                <div className="frame-2">
                  <div className="text-wrapper">Sign Out</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-3">
        <div className="group-wrapper">
          <div className="group">
            <div className="group-2">
              <div className="text-wrapper-2">Tell Us More</div>
            </div>
          </div>
        </div>
        <img className="line" src="https://c.animaapp.com/uXeCcQLH/img/line-26.svg" alt=''  />

     {step === 1 && <StepOne handleNextStep={handleNextStep} />}
     {step === 2 && <StepTwo handleNextStep={handleNextStep} />}
     {step === 3 && <StepThree handleNextStep={handleNextStep} />}
     {step === 4 && <StepFour handleNextStep={handleNextStep} />}
     {step === 5 && <StepFive handleNextStep={handleNextStep} />}
     {/* {step === 6 && <StepSix handleNextStep={handleNextStep} />} */}
  
        
      </div>
      <div className="frame-13">
        <div className="text-wrapper-9">7%</div>
        <img
          className="frame-14"
          src="https://c.animaapp.com/uXeCcQLH/img/frame-9826.svg"
          alt='' 
        />
      </div>
    </div>
  );
};
