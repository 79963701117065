import React, { useEffect, useState } from 'react';
import { minus, add } from '../../assets/images';
import { HashLink } from 'react-router-hash-link';

import { useNavigate } from 'react-router-dom';

const Privacy: React.FC = () => {
  const navigate = useNavigate();
  const redirectSignup = () => {
    navigate('/signup');
  };

  const [sideActive, setSideActive] = useState('DataProtection');

  const onSetActive = (item: any) => {
    setSideActive(item);
  };

    React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="body-dashboard">
      {/* section one */}
      <div
        className=" d-flex justify-content-space between title-body"
        id="sendMoney"
      >
        <div className="title-body-text-2">
          <span className="header-title text-aling-header ">
            Privacy Policy
          </span>

          <div className="feature-desc-container mt-9 mb-9">
            <span className="feature-description">
              We respect your privacy, and we want to protect your personal
              information. To learn more, please read through our Privacy
              Policy.
            </span>
          </div>
        </div>
      </div>

      {/* section three */}
      <div className="features-container-3 min-h-500px">
        <div className="faq-con">
          <div className="faq-side">
            <HashLink to={'/privacy/#DataProtection'}>
              <span
                className={sideActive === 'DataProtection' ? 'active' : ''}
                onClick={() => onSetActive('DataProtection')}
              >
                Data Protection
              </span>
            </HashLink>
            <HashLink to={'/privacy/#Consent'}>
              <span
                className={sideActive === 'Consent' ? 'active' : ''}
                onClick={() => onSetActive('Consent')}
              >
                Consent
              </span>
            </HashLink>
            <HashLink to={'/privacy/#DataCollected'}>
              <span
                className={sideActive === 'DataCollected' ? 'active' : ''}
                onClick={() => onSetActive('DataCollected')}
              >
                Information and Data Collected
              </span>
            </HashLink>
            <HashLink to={'/privacy/#ThirdParties'}>
              <span
                className={sideActive === 'ThirdParties' ? 'active' : ''}
                onClick={() => onSetActive('ThirdParties')}
              >
                Third Parties and Links
              </span>
            </HashLink>
            <HashLink to={'/privacy/#Security'}>
              <span
                className={sideActive === 'Security' ? 'active' : ''}
                onClick={() => onSetActive('Security')}
              >
                Security
              </span>
            </HashLink>
            <HashLink to={'/privacy/#Rights'}>
              <span
                className={sideActive === 'Rights' ? 'active' : ''}
                onClick={() => onSetActive('Rights')}
              >
                Rights
              </span>
            </HashLink>
            <HashLink to={'/privacy/#Cookies'}>
              <span
                className={sideActive === 'Cookies' ? 'active' : ''}
                onClick={() => onSetActive('Cookies')}
              >
                Cookies
              </span>
            </HashLink>
          </div>
          <div className="faq-main">
            <div className="privacy-body" id="Transfer">
              <span className="faq-header  mb-3">
                Our Privacy Policy explains the following
              </span>
              <div className="privacy-list">
                <ol type="I">
                  <li> How we make use of your personal information</li>
                  <li>
                    {' '}
                    The steps we have taken to secure your personal information
                  </li>
                  <li>
                    {' '}
                    Your options regarding the use of your personal information.
                    By visiting our page directly or through another site, you
                    accept the practices described in this policy.
                  </li>
                </ol>
              </div>
            </div>

            <div className="privacy-body" id="DataProtection">
              <span className="faq-header  mb-3">1. Data Protection</span>
              <div className="privacy-list">
                <span>
                  Data Protection is a matter of trust and your privacy is
                  important to us. We shall therefore only use your name and
                  other information which relates to you in the manner set out
                  in the Privacy Policy. We will only collect information on the
                  basis of necessity and relevance to us, in our dealings with
                  you.
                  <br />
                  <br />
                  We will only keep information for as long as we are either
                  required to by law or as is relevant for the purposes for
                  which it was collected.
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="Consent">
              <span className="faq-header  mb-3">2. Consent</span>
              <div className="privacy-list">
                <span>
                  Knowledge and consent are required for the collection, use or
                  disclosure of personal information except where required or
                  permitted by law. Providing us with your personal information
                  is always your choice. However, your decision not to provide
                  certain information may limit our ability to provide you with
                  our products. you.
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="DataCollected">
              <span className="faq-header  mb-3">
                3. Information and Data Collected
              </span>
              <div className="privacy-list">
                <span>
                  Various types of information may be collected if you are
                  accessing any of our web page, mobile application or other
                  platforms controlled and managed by us online or offline. We
                  collect and store your data for processing your requests,
                  services and any possible later claims and to provide you with
                  our services. We may collect personal information including
                  but not limited to title, name, email address, postal address,
                  delivery address, mobile number, payment details, payment card
                  details or bank account details.
                  <br />
                  <br />
                  We will use the information you provide to process your
                  requests and to provide you with the services and information
                  offered through our web page, mobile and other applications
                  which you have requested for.
                  <br />
                  <br />
                  Furthermore, the information you provide will be used to
                  administer your account with us; verify and carry out
                  financial transactions in relation to online payments; improve
                  the layout and/or content of our web page, applications,
                  services and customize them for you; identify visitors on our
                  web and applications; send you information we think you may
                  find useful or that which you may have requested from us
                  (provided you have indicated that you have not objected to
                  being contacted for these purposes) subject to obtaining your
                  consent, we may contact you by email with details of other
                  products and services rendered by us. If you prefer not to
                  receive any marketing communications from us, you can opt out
                  at any time.
                  <br />
                  <br />
                  You must only submit to us, our agents or accredited
                  representatives, information which is up to date, accurate and
                  not misleading. You undertake to treat your personal data
                  confidentially and not make it available to unauthorized third
                  parties. We would not assume any liability for misuse of
                  passwords.
                  <br />
                  <br />
                  Your email address may be used for surveys, competitions and
                  opinion polls. Any answers to surveys or opinion polls will
                  not be forwarded to third parties. We may also send you
                  information about us, our services, our other websites, our
                  products, sales and promotions, our newsletters etc. If you
                  would prefer not to receive any of this additional information
                  as described above, please click the ‘unsubscribe’ link in any
                  of the emails sent to you or adjust your customer
                  communication preferences on the application.
                  <br />
                  <br />
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="ThirdParties">
              <span className="faq-header  mb-3">
                4. Third Parties and Links
              </span>
              <div className="privacy-list">
                <span>
                  We may pass your name, address and mobile number on to a third
                  party in order to make delivery of our services to you,
                  analyze data and to provide us with marketing or customer
                  service assistance. We may exchange information with third
                  parties for the purposes of fraud protection. We may transfer
                  our database containing personal information where the need
                  arises. Other than as set out in this Privacy Policy, we shall
                  NOT sell or disclose your personal data to third parties
                  without obtaining your prior consent unless this is necessary
                  for the purposes set out in this Privacy Policy or unless we
                  are required to do so by law.
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="Security">
              <span className="faq-header  mb-3">5. Security</span>
              <div className="privacy-list">
                <span>
                  We have put in place appropriate technical and security
                  measures to prevent unauthorized or unlawful access to or
                  accidental loss of, destruction or damage to your information.
                  When data is collected, your personal details are stored on a
                  secure server which contains firewalls. We work to protect the
                  security of your information during transmission by using
                  Secure Sockets Layer (SSL) which encrypts information you
                  input for the processing of your transactions. We have put
                  processes in place that ensure details of your payment
                  instruments such as credit/debit card, bank account details
                  are not compromised nor put at risk as you transact with us.
                  <br />
                  <br />
                  We maintain physical, electronic and procedural safeguards in
                  connection with the collection, storage and disclosure of your
                  information. It is important for you to protect yourself
                  against unauthorized access to your password and to your
                  computer. Be sure to sign off when you finish using a shared
                  computer.
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="Rights">
              <span className="faq-header  mb-3">6. Rights</span>
              <div className="privacy-list">
                <span>
                  If you are concerned about your data, you have the right to
                  request access to the personal data which we may hold or
                  process about you. You have the right to require us to correct
                  any inaccuracies in your data free of charge. At any stage you
                  also have the right to ask us to stop using your personal data
                  for direct marketing purposes.
                </span>
              </div>
            </div>

            <div className="privacy-body mt-9" id="Cookies">
              <span className="faq-header  mb-3">7. Cookies</span>
              <div className="privacy-list">
                <span>
                  Cookies are tiny text files which identify your computer to
                  our server as a unique user when you visit certain pages and
                  they are stored by your internet browser on your computer’s
                  hard drive. We only use cookies for your convenience and not
                  for obtaining or using any other information. The use of
                  cookies does not contain any personal or private details and
                  are free from viruses. Please refer to our Cookies policy for
                  more information.{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
