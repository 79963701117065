import { Button } from "antd";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { backIcon } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";

interface PinProps {
  proceed: (e: any) => void;
  header: string;
  message: string;
  numOfInputs?: number;
  canResend: boolean;
  resendFn?: any;
  resendText?: string;
  doneText?: string;
  timeRemaining?: any
}
const Pin: React.FC<PinProps> = ({
  proceed,
  header,
  message,
  numOfInputs,
  canResend,
  resendFn,
  resendText,
  doneText,
  timeRemaining
}) => {
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();

  const location = useLocation();
  
  var loc = sessionStorage.getItem("location");

   



  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="main-container p-7 mt-5">
      <div>
        <div className="d-flex align-items-center my-6 ms-3r">
          <img
            src={backIcon}
            alt=""
            className="w-30px h-30px"
            onClick={() => goBack()}
          />

          <div className="ms-5">
            <span className="fs-2xx fw-bolder text-dblack ">{header}</span>
          </div>
        </div>

        <div className="otp-container">
          <div>
            <span className="enter-otp">{message}</span>
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={numOfInputs || 4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={"otp-box mt-5"}
            inputStyle={"w-100"}
          />

          
                  {timeRemaining.seconds && (
            <div onClick={() => resendFn()} style={{marginTop: "1rem"}}>
              <span >
                   <div>

        <p> <span style={{color: "black"}}>Resend OTP in </span> <span  className="resend-text">{timeRemaining.seconds}s</span></p>
    
    </div>
              </span>
            </div>
          )}

          <div>
            <Button
              htmlType="submit"
              disabled={otp.length !== 4}
              className="done-btn"
              onClick={() => proceed(otp)}
            >
              <span>{doneText || "Done"}</span>
            </Button>
          </div>

          {canResend && loc !== '/verify-signup' && (
            <button  style={{border: "none", backgroundColor: "transparent"}} onClick={() => resendFn()} disabled={timeRemaining.seconds > 0}>
              <span className={timeRemaining.seconds > 0 ? 'resent-text-inactive' : 'resend-text' }>{resendText || "Resend OTP"}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { Pin };
