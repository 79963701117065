import React, { useEffect, useState } from 'react';
import { minus, add } from '../../assets/images';
import { HashLink } from "react-router-hash-link";

import { useNavigate } from 'react-router-dom';

const FAQ: React.FC = () => {
  const navigate = useNavigate();
  const redirectSignup = () => {
    navigate('/signup');
  };

  const [sideActive, setSideActive] = useState('Registration');

  const onSetActive = (item: any) => {
    setSideActive(item);
  };

    React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(0);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };

  const faqRegistration = [
    {
      title: 'Who can create an account?',
      desc: 'Any eligible adult that is able to prove his/her identity with any government recognized ID card.',
    },
    {
      title: 'How can I create an account?',
      desc: 'Getting started has been made simple and easy. To create an account click sign up from the Monibag website, you will be required to provide some information and documents to enable us to verify your identity and complete your account registration. ',
    },
    {
      title: 'How can I send money?',
      desc: 'You can refer to how it works for a step-by-step guide on how to successfully send money.',
    },
    {
      title: 'What services does Monibag offer?',
      desc: 'Monibag offers remittance services. That is, sending money to your loved ones or for personal use has been made easy.Using Monibag , you can send money to any of our partner banks for cash pick up or directly to the recipient’s account at a very low cost.Register or Login here to start sending money today.',
    },
    {
      title: 'Where can I send money to?',
      desc: 'With Monibag , you can send money to Nigeria and some selected countries in Europe. See list of countries. (A page showing a list of the countries allowed to access its service, if need be.)',
    },
    {
      title: 'What receiving banks can I send money to?',
      desc: 'ZeusRemit has partnered with banks in all receiving countries to help simplify the recipient’s experience.',
    },
    {
      title: 'List of monibag partner banks?',
      desc: 'Fidelity Bank, Zenith Bank, FCMB ,Access Bank, List of partner banks in Europe (A list of banks can be included when this has been finalized)',
    },
  ];

  const faqTransfer = [
    {
      title: 'How can I confirm or check my transfer?',
      desc: 'You can confirm your transaction status by easily navigating to your transaction history. You will always get a confirmation email to keep you  informed of your transaction status. Kindly ensure to check your email for such information or contact us here (A link that redirects to support email) if you do not receive an email confirming reasons for the delay).',
    },
    {
      title: 'How long does it take money transferred to be delivered?',
      desc: `<p>Money transfer takes minutes, at most a few hours to be delivered to the recipient’s bank account or cash pickup point. In some cases, delays may be experienced as a result of:</p>

  <ul>
    <li>Operational differences among paying banks</li>
    <li>The time of the transfer: transactions initiated at the end of the day or on a public holiday may take additional time before the recipient gets value. Transfers made at such times are usually moved to the next working day.</li>
  </ul>

  <p>If the transaction is yet to be completed after 24 hours, please <a href="mailto:support@example.com">contact customer service</a> for further assistance.</p>

  <p>The accuracy of transaction details, including those of the recipient. Please ensure the accuracy of all transaction details before clicking send.</p>

  <p>If you are experiencing any form of delay and wish to make a complaint or need support, please <a href="mailto:support@example.com">contact support</a>.</p>,
    `},
    {
      title: 'How is the money delivered to the recipient?',
      desc: '',
    },
    {
      title: 'What are the exchange rates?',
      desc: '',
    },
    {
      title: 'Why are the recipients email address and phone number required?',
      desc: '',
    },
    {
      title: 'What is the Cash pickup password?',
      desc: '',
    },
    {
      title: 'What is required of a recipient at a pickup location?',
      desc: '',
    },
  ];

  const faqMyAccount = [
    {
      title: 'How can I make modifications to my account?',
      desc: '',
    },
    {
      title: 'Do I get charged when I sign up?',
      desc: '',
    },
    {
      title: 'How can I make a complaint?',
      desc: '',
    },
    {
      title: 'How do I add a recipient?',
      desc: '',
    },
    {
      title: 'How can I delete my monibag account?',
      desc: '',
    },
  ];

  const faqDocument = [
    {
      title: 'Why is my proof of identity and additional documents required?',
      desc: '',
    },
  ];

  const faqSecurity = [
    {
      title: 'How can I change my password?',
      desc: '',
    },

    {
      title: 'I am having trouble logging into the App.',
      desc: '',
    },

    {
      title: 'The website wouldn’t work.',
      desc: '',
    },
  ];

  const faqCashPickup = [
    {
      title: 'What is required of a recipient at a pickup location?',
      desc: '',
    },
  ];

  return (
    <div className="body-dashboard">
      {/* section one */}
      <div
        className=" d-flex justify-content-space between title-body"
        id="sendMoney"
      >
        <div className="title-body-text-2">
          <span className="header-title text-aling-header ">
            Frequently asked questions
          </span>

          <div className="feature-desc-container mt-9">
            <span className="feature-description">
              Everything you need to know about monibag and how it works. Can’t
              find what you are looking for?
            </span>
          </div>

          <span className="d-flex align-items-center itm-cen">
            <button className="started-btn" onClick={redirectSignup}>
              Get In Touch
            </button>
          </span>
        </div>
      </div>

      {/* section three */}
      <div className="features-container-3 min-h-500px">
        <div className="faq-con">
          <div className="faq-side">
             <HashLink to={"/faq/#Registration"}>
            <span
              className={sideActive === 'Registration' ? 'active' : ''}
              onClick={() => onSetActive('Registration')}
            >
              Registration
              </span>
            </HashLink>
            <HashLink to={"/faq/#Transfer"}>
                 <span
              className={sideActive === 'Transfer' ? 'active' : ''}
              onClick={() => onSetActive('Transfer')}
            >
              Transfer
            </span>
               </HashLink>
            <HashLink to={"/faq/#Account"}>
             <span
              className={sideActive === 'Account' ? 'active' : ''}
              onClick={() => onSetActive('Account')}
            >
              My Account
            </span>
            </HashLink>
                     <HashLink to={"/faq/#Documents"}>

            <span
              className={sideActive === 'Documents' ? 'active' : ''}
              onClick={() => onSetActive('Documents')}
            >
              Documents
              </span>
            </HashLink>
            <HashLink to={"/faq/#Security"}>
               <span
              className={sideActive === 'Security' ? 'active' : ''}
              onClick={() => onSetActive('Security')}
            >
              Security
            </span>
             </HashLink>
            <HashLink to={"/faq/#Pickup"}>
             <span
              className={sideActive === 'Pickup' ? 'active' : ''}
              onClick={() => onSetActive('Pickup')}
            >
              Cash Pickup
            </span>
            </HashLink>
         
          </div>
          <div className="faq-main">
            {/* <div>
              <input type="text" placeholder="Search for any question here" />
            </div> */}

            <div className="faq-body"  id="Registration">
              <span className="faq-header mb-5" >Registration</span>
              {faqRegistration.map((value, index) => {
                return (
                  <div key={index} >
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="faq-body" id='Transfer'>
              <span className="faq-header mb-5">Transfer</span>
              {faqTransfer.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="faq-body" id='Account'>
              <span className="faq-header mb-5">My Account</span>
              {faqMyAccount.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="faq-body" id='Document'>
              <span className="faq-header mb-5">Document</span>
              {faqDocument.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="faq-body" id='Security'>
              <span className="faq-header mb-5">Security</span>
              {faqSecurity.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="faq-body" id='Pickup'>
              <span className="faq-header mb-5">Cash Pickup</span>
              {faqCashPickup.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className="faq-body-inner"
                      onClick={() => handleFaq(index)}
                    >
                      <div>{value.title}</div>
                      <div>
                        {arrow && FaqNo === index ? (
                          <span>
                            <img src={minus} className="w-100 h-100" alt="" />
                          </span>
                        ) : (
                          <span>
                            <img src={add} className="w-100 h-100" alt="" />
                          </span>
                        )}
                      </div>
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body-inner-content">
                        <span>{value?.desc}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
