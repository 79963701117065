import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useRequestResetPassword } from '../useAuth';
import { useNavigate } from 'react-router-dom';

const NewPassword: React.FC = () => {
  const [form] = Form.useForm();
  const [isPasswordFormInvalid, setIsPasswordFormInvalid] = useState(true);

    const { data, mutate, isLoading } = useRequestResetPassword();
  var email = sessionStorage.getItem('email');

  const navigate = useNavigate();

  const passwordFormSuccessful = (values: any) => {
    console.log('Success:', values);

  let payload =  {
    "email": email ,
    "new_password": values.password,
    "confirm_new_password": values.confirmPassword
}


      mutate(
      payload,
      {
        onSuccess: async (res: any) => {
          console.log(res);

          if (
            res?.data?.response_code === 200 ||
            res?.data?.status_code === 200
          ) {
              navigate('/', { replace: true });

        
          }
        },
      }
    );
  };

  const passwordFormFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validatePasswordForm = async () => {
    try {
      await form.validateFields();
      setIsPasswordFormInvalid(false);
    } catch (error) {
      setIsPasswordFormInvalid(true);
    }
  };

  const validatePassword = async (_:any, value: any) => {
    const password = form.getFieldValue('password');
    console.log(password, value)
    if (value !== password) {
      setIsPasswordFormInvalid(true)
      throw new Error('The passwords do not match');
    } else {
      setIsPasswordFormInvalid(false)




    }
  };


  return (
      <div className="main-container p-3 mt-5">
        <div className={'loginForm'}>
          <h1>New Password</h1>

          <Form
            form={form}
            name="passowrdForm"
            initialValues={{ remember: true }}
            onFinish={passwordFormSuccessful}
            onFinishFailed={passwordFormFailed}
            autoComplete="off"
            layout="vertical"
          >
              <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                      { required: true, message: 'Please enter a new password' },
                  ]}
              >
                  <Input.Password
                      placeholder={'Password'}
                      prefix={
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.7258 7.83597V6.04513C13.7 3.94596 11.9767 2.26596 9.87833 2.2918C7.8225 2.31763 6.16 3.97263 6.125 6.02847V7.83597" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9.92521 11.7966V13.6475" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.92532 7.35327C5.13783 7.35327 3.54199 8.65994 3.54199 12.5791C3.54199 16.4991 5.13783 17.8058 9.92532 17.8058C14.7128 17.8058 16.3095 16.4991 16.3095 12.5791C16.3095 8.65994 14.7128 7.35327 9.92532 7.35327Z" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                      }
                      onBlur={validatePasswordForm}
                  />
              </Form.Item>

              <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                      { required: true, message: 'Please enter your new password again' },
                      { validator: validatePassword },
                  ]}
                  dependencies={['password']}
              >
                  <Input.Password
                      placeholder={'Password'}
                      prefix={
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.7258 7.83597V6.04513C13.7 3.94596 11.9767 2.26596 9.87833 2.2918C7.8225 2.31763 6.16 3.97263 6.125 6.02847V7.83597" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9.92521 11.7966V13.6475" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.92532 7.35327C5.13783 7.35327 3.54199 8.65994 3.54199 12.5791C3.54199 16.4991 5.13783 17.8058 9.92532 17.8058C14.7128 17.8058 16.3095 16.4991 16.3095 12.5791C16.3095 8.65994 14.7128 7.35327 9.92532 7.35327Z" stroke="#A4A4A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                      }
                      onBlur={validatePasswordForm}
                  />
              </Form.Item>

              <Form.Item>
                  <Button  htmlType="submit" disabled={isPasswordFormInvalid}>
                      <span>Login</span>
                  </Button>
              </Form.Item>
          </Form>
      </div>

    </div>
  );
};

export default NewPassword;
