import React, { useEffect, useState } from 'react';
import { useRequestAPIkey } from '../Auth/useAuth';


import './style.css';
import { useNavigate } from 'react-router-dom';

export const DeveloperPortal = (): JSX.Element => {
  const navigate = useNavigate();

  const { data, mutate, isLoading } = useRequestAPIkey();

  
  
  useEffect(() => {
mutate()
  }, [])


  const authenticationRoute = () => {
    console.log('here');

    return (window.location.href = 'https://dev.monibag.com/portal/auth');
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(data?.data?.data)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error('Unable to copy text: ', err);
      });
  };

  const redirectVerify = () => {
    navigate('/developer-portal/verify');
  };
  return (
    <div className="DevFrame">
      <div className="developers-portal">
        <div className="overlap-group">
          <div className="DevFrame-wrapper">
            <div className="div">
              <div className="div-2">
                <div className="group">
                  <div className="div-wrapper">
                    <div className="text-wrapper">Developer Portal</div>
                  </div>
                </div>
                <p className="p">
                  Please upload all required documents for compliance
                  verification
                </p>
              </div>
              <div className="DevFrame-wrapper-2" onClick={redirectVerify}>
                <div className="div-wrapper-2">
                  <div className="text-wrapper-2">Verify Account</div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="star"
            src="https://c.animaapp.com/gJEU2wMl/img/star-2.svg"
            alt=''
          />
          <img
            className="img"
            src="https://c.animaapp.com/gJEU2wMl/img/star-1.svg"
            alt=''
          />
        </div>
      </div>
      <div className="div-3">
        <div className="div-4">
          <div className="div-wrapper-3">
            <div className="text-wrapper-3">Test</div>
          </div>
          <div className="div-wrapper-4">
            <div className="text-wrapper-4">UAT</div>
          </div>
          <div className="div-wrapper-5">
            <div className="text-wrapper-4">Production</div>
          </div>
        </div>
        <div className="DevFrame-wrapper-3">
          <div className="div-5">
            <div className="text-wrapper-5">Add request header x-api-key</div>
            <div className="div-6">
              <div
                className="vuesax-outline-eye-wrapper"
                onClick={handleCopyClick}
              >
                <span className="vuesax-outline-eye-text">
                  {' '}
                  {data?.data?.data}
                </span>
                <img
                  className="vuesax-outline-eye"
                  src="https://c.animaapp.com/gJEU2wMl/img/vuesax-outline-eye.svg"
                  alt=''
                />
              </div>
              <div className="div-7">
                <img
                  className="vuesax-outline-key"
                  src="https://c.animaapp.com/gJEU2wMl/img/vuesax-outline-key-1.svg"
                  alt=''
                />
                <div className="text-wrapper-6">API Key</div>
                                 {isCopied ?<span style={{color: '#fff', fontWeight: "bolder"}}>Copied!</span> : ''}

              </div>
            </div>
          </div>
        </div>
        <div className="div-8">
          <div className="div-9" onClick={authenticationRoute}>
            <div className="div-10">
              <div className="group-2">
                <img
                  className="vector"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-12.svg"
                  alt=''
                />
                <img
                  className="vector-2"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-43.svg"
                  alt=''
                />
                <img
                  className="vector-3"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-44.svg"
                  alt=''
                />
                <img
                  className="img-2"
                  src="https://c.animaapp.com/gJEU2wMl/img/monibagillustrationapi-1@2x.png"
                  alt=''
                />
              </div>
            </div>
            <div className="text-wrapper-7">Authentication API</div>
          </div>
          <div className="div-9">
            <div className="div-10">
              <div className="imtonigeria">
                <div className="overlap">
                  <div className="overlap-group-2">
                    <img
                      className="vector-4"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-11.svg"
                      alt=''
                    />
                    <img
                      className="vector-5"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-10.svg"
                        alt=''
                    />
                    <img
                      className="vector-6"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-9.svg"
                        alt=''
                    />
                    <img
                      className="vector-7"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-8.svg"
                        alt=''
                    />
                    <img
                      className="vector-8"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-7.svg"
                        alt=''
                    />
                    <img
                      className="vector-9"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-6.svg"
                        alt=''
                    />
                    <img
                      className="vector-10"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-3.svg"
                        alt=''
                    />
                    <div className="text-wrapper-8">$</div>
                    <img
                      className="vector-11"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-2.svg"
                        alt=''
                    />
                    <div className="text-wrapper-9">N</div>
                    <img
                      className="vector-12"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-1.svg"
                        alt=''
                    />
                    <img
                      className="vector-13"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector.svg"
                        alt=''
                    />
                  </div>
                  <img
                    className="vector-14"
                    src="https://c.animaapp.com/gJEU2wMl/img/vector-5.svg"
                      alt=''
                  />
                  <img
                    className="vector-15"
                    src="https://c.animaapp.com/gJEU2wMl/img/vector-4.svg"
                      alt=''
                  />
                </div>
              </div>
            </div>
            <div className="text-wrapper-7">IMTO Nigeria API</div>
          </div>
        </div>
      </div>
    </div>
  );
};
