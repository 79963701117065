import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Form, Modal, Spin } from 'antd';
import { useRequestDocumentUpload } from '../../../Auth/useAuth';
import { useNavigate } from 'react-router-dom';

interface Step1Props {
  handleNextStep: () => void;
}

interface FileUploaderProps {}

export const StepSix: React.FC<FileUploaderProps> = () => {
  const [form] = Form.useForm();

    const navigate = useNavigate();

  const [showModal, setModal] = useState(false);

  const { data, error, mutate, isLoading } = useRequestDocumentUpload();

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmailForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {}
  };

  const next = () => {
    return (
      <div>
        <span>Save And Continue</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.56224 13.4062H17.7466L14.3646 9.11719C14.2685 8.99531 14.3552 8.8125 14.5122 8.8125H16.031C16.2607 8.8125 16.4787 8.91797 16.6193 9.09844L20.463 13.9734C20.8497 14.4656 20.5005 15.1875 19.8747 15.1875H3.56224C3.45912 15.1875 3.37474 15.1031 3.37474 15V13.5938C3.37474 13.4906 3.45912 13.4062 3.56224 13.4062Z"
            fill="#FEF8E6"
          />
        </svg>
      </div>
    );
  };

  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const updatedFiles = [...files, ...acceptedFiles];
      setFiles(updatedFiles);
    },
    [files]
  );

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    // Append each file to the formData
    files.forEach((file, index) => {
      formData.append('document', file);
    });

    try {
      // Replace 'YOUR_BACKEND_ENDPOINT' with the actual endpoint

      console.log(formData);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }

      mutate(formData, {
        onSuccess: async (res: any) => {
          setModal(true);
        },
        onError: (res: any) => {},
        onSettled: (res: any) => {},
      });

      // Optionally, you can clear the uploaded files after successful upload
      setFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


  const redirectHome = () => {
     navigate('/developer-portal/home', { replace: true });

  }

  return (
    <>
      <div className="DOCUMENT-UPLOAD">
        <div className="navbar">
          <div className="frame">
            <img
              className="img"
              src="https://c.animaapp.com/f0c1WPKh/img/frame-19.svg"
            />
            <div className="frame-wrapper">
              <div className="div-wrapper">
                <div className="div">
                  <div className="frame-2">
                    <div className="text-wrapper">Sign Out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-3">
          <img
            className="vuesax-outline-arrow"
            src="https://c.animaapp.com/f0c1WPKh/img/vuesax-outline-arrow-right-1@2x.png"
            alt=""
          />
          <div className="text-wrapper-2">Back</div>
        </div>
        <div className="frame-4">
          <div className="frame-5">
            <div className="frame-6">
              <div className="group">
                <div className="group-2">
                  <div className="text-wrapper-3">Document Center</div>
                </div>
              </div>
              <div className="frame-7">
                <p className="p">
                  Please upload all required documents for compliance
                  verification
                </p>
                <div className="frame-8">
                  <div className="text-wrapper-4">Skip To Portal</div>
                  <img
                    className="vuesax-outline-arrow"
                    src="https://c.animaapp.com/f0c1WPKh/img/vuesax-outline-arrow-right.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="frame-5">
              <div className="frame-9">
                <p className="text-wrapper-5">
                  Company Registration Documents (including directors’ details /
                  means of identification)
                </p>
                <div className="text-wrapper-6">Company Profile</div>
                <div className="text-wrapper-6">AML/CFT Policy Manual</div>
                <div className="text-wrapper-6">KYC Policy Manual</div>
                <div className="text-wrapper-6">Data Protection Policy</div>
                <div className="text-wrapper-6">Operating License</div>
                <div className="text-wrapper-7">
                  Customer Dispute Resolution Policy
                </div>
                <div className="text-wrapper-7">
                  Information Security Policy
                </div>
                <div className="text-wrapper-7">
                  Document/Record Retention Policy
                </div>
              </div>
              <img
                className="line"
                src="https://c.animaapp.com/f0c1WPKh/img/line-21.svg"
              />
            </div>
          </div>
          <div className="frame-10">
            <div className="frame-11">
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'active' : ''}`}
              >
                <input {...getInputProps()} />
                <div className="frame-12">
                  <div className="text-wrapper-8">Upload multiple files</div>
                </div>
                <div className="frame-13">
                  <div className="frame-14">
                    <img
                      className="cloud-upload"
                      src="https://c.animaapp.com/f0c1WPKh/img/cloud-upload.svg"
                    />
                    <div className="frame-15">
                      <div className="text-wrapper-9">Drop files here</div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <p className="or-browse-file-from">
                      <span className="span">Or </span>
                      <span className="text-wrapper-10">Browse file</span>
                      <span className="span"> from your computer.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-16">
              <div className="frame-17">
                <div className="checkmark-circle-wrapper">
                  <div className="checkmark-circle">
                    <img
                      className="union"
                      src="https://c.animaapp.com/f0c1WPKh/img/union-1.svg"
                    />
                  </div>
                </div>
                <div className="text-wrapper-11">
                  {files?.length} files uploaded
                </div>
              </div>
              <div className="frame-18">
                <div className="frame-19">
                  <div className="frame-20">
                    <div className="frame-21">
                      <div className="text-wrapper-12">File Uploader</div>
                    </div>
                    <div className="frame-22">
                      <div className="text-wrapper-12">Type of Document</div>
                    </div>
                  </div>
                  <div className="frame-23">
                    <div className="text-wrapper-12">Action</div>
                  </div>
                </div>

                {files.length > 0 && (
                  <div>
                    {files.map((file, index) => {
                      return (
                        <div className="frame-24" key={index}>
                          <div className="frame-25">
                            <div className="frame-21">
                              <div className="text-wrapper-13">
                                {' '}
                                {file.name}{' '}
                              </div>
                            </div>
                            <div className="frame-26">
                              <div className="text-wrapper-14">
                                Cooperate affairs commission
                              </div>
                              <img
                                className="vuesax-outline-arrow-2"
                                src="https://c.animaapp.com/f0c1WPKh/img/vuesax-outline-arrow-down-4.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="frame-23">
                            <div className="send-circle-wrapper">
                              <div
                                className="send-circle"
                                onClick={() => removeFile(index)}
                              >
                                <img
                                  className="akar-icons-plus"
                                  src="https://c.animaapp.com/f0c1WPKh/img/akar-icons-plus-3.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="frame-27">
              <div className="frame-12">
                <p className="please-select-the">
                  Please select the document type for each of the files uploaded
                  <br />
                  and then click below to submit your files
                </p>
              </div>

              {
                isLoading ?   <div className="frame-28" >
                <div className="text-wrapper-15" >
                   <Spin />
                </div>
              </div> :   <div className="frame-28" onClick={handleUpload}>
                <div className="text-wrapper-15" >
                  Submit 
                </div>
              </div>
              }

            
            </div>
          </div>
        </div>
      </div>

      <Modal open={showModal} footer={null} maskClosable = {false}   onCancel={() => redirectHome()}>
        <div className="document-upload">
          <div className="frame">
            <div className="group">
              <img
                className="img"
                src="https://c.animaapp.com/qxmYPddL/img/frame-9820-1.svg"
                alt=''
              />
              <div className="div">
                <img
                  className="star"
                  src="https://c.animaapp.com/qxmYPddL/img/star-3.svg"
                    alt=''
                />
                <img
                  className="star-2"
                  src="https://c.animaapp.com/qxmYPddL/img/star-4.svg"
                    alt=''
                />
                <div className="rectangle"></div>
                <div className="rectangle-2"></div>
              </div>
              <div className="frame-2">
                <img
                  className="star-3"
                  src="https://c.animaapp.com/qxmYPddL/img/star-5.svg"
                    alt=''
                />
                <div className="rectangle-3"></div>
                <div className="rectangle-4"></div>
                <div className="ellipse"></div>
                <div className="ellipse-2"></div>
              </div>
            </div>
          </div>
          <div className="frame-3">
            <div className="div-wrapper">
              <div className="text-wrapper">Uploaded Successfully!</div>
            </div>
            <p className="our-compliance-team">
              Our compliance team will review on documents
              <br />
              and get back in the shortest possible time. Feel free
              <br />
              to explore our API options.
            </p>
          </div>
          <div className="frame-wrapper" onClick={redirectHome}>
            <div className="frame-4">
              <div className="text-wrapper-2">Done </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
