import React from "react";
import { useNavigate } from "react-router-dom";
import {
  appStore,
  arrowPoint,
  backBgWhite,
  check,
  childWrite,
  filesentBro,
  mission1,
  mission2,
  mission3,
  mission4,
  mobilePhone,
  multiCurrency,
  playStore,
  step1,
  step2,
  step3,
  transactionPreview,
  wallet,
} from "../../assets/images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Dashboard: React.FC = () => {
  const features = [
    "Simple and fast remittance service",
    "Multi-currency bank account to support our global customers",
    "Mobile digital wallet for fast and reliable payment for good and services",
  ];

  const [windowWith, setWindowWith] = React.useState<number>(
    window.screen.availWidth
  );

  const handleResize = () => {
    setWindowWith(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

   const redirectSignup = () => {
    navigate("/signup");
  };

  const features3 = [
    {
      active: true,
      image: wallet,
      title: "remittance",
      description:
        "Send money instantly to individuals or businesses in any of our supported countries in 3 easy steps",
    },

    {
      active: false,
      image: multiCurrency,
      title: "multi-currency",
      description:
        "Go borderless - receive and send funds in any of our supported currencies",
    },

    {
      active: false,
      image: mobilePhone,
      title: "MOBILE DIGITAL WALLET",
      description:
        "Reliable payment for goods and services with a virtual card in any of our supported currenciess",
    },
  ];

  const itemsPerScreen = windowWith <= 540 ? 1 : 3; // Number of items to show per screen

  // Split features into arrays of itemsPerScreen items each
  const featureGroups = [];
  for (let i = 0; i < features3.length; i += itemsPerScreen) {
    featureGroups.push(features3.slice(i, i + itemsPerScreen));
  }

  const navigate = useNavigate();
  const redirectLogin = () => {
    navigate("/signup");
  };

    React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body-dashboard">
      {/* section one */}
      <div
        className=" d-flex justify-content-space between title-body"
        id="sendMoney"
      >
        <div className="title-body-text">
          <span className="header-title ">
            FAST AND CONVENIENT WAY TO{" "}
            <span className="text-yellow">SEND MONEY</span>
          </span>
          <div>
            <span className="d-flex align-items-center">
              <button className="started-btn" onClick={redirectLogin}>
                GET STARTED
              </button>
            </span>
          </div>

          <div className="w-100">
            <span className="tc">
              By clicking continue, you agree to our{" "}
              <a className="highlightTc">Terms</a> and{" "}
              <a className="highlightTc">Privacy Policy</a>
            </span>
          </div>

          <div className="store">
            <div className="apple">
              <img src={appStore} className="w-100 h-100" />
            </div>

            <div className="google">
              <img src={playStore} className="w-100 h-100" />
            </div>
          </div>

          <div className="d-flex flex-column">
            <div>
              <span className="text-white fs-2 fw-bolder">
                For API Integration
              </span>
            </div>
            <div>
              <a
                className="text-yellow fs-base fw-bold"
                href="/developer-portal"
              >
                Developer Portal
              </a>

              <img src={backBgWhite} className="ms-5" />
            </div>
          </div>
        </div>

        <div className="d-none d-lg-block transImgbg">
          <img
            src={transactionPreview}
            className="transactionPreviewImg"
            alt=""
          />
        </div>
      </div>
      {/* section two */}
      <div className="bg-white min-h-500px" id="howitworks">
        <div className="child-write d-none d-lg-block">
          <img src={childWrite} />
        </div>

        <div className="d-flex flex-column align-items-center steps-container">
          <div>
            <span className="title">3 SIMPLE STEPS</span>
          </div>

          <div className=" d-flex sub-title-container align-itens-center justify-content-center  ">
            <span className="sub-title">
              TRANSFER MONEY FAST AND CONVENIENT
            </span>
          </div>

          <div className="steps ">
            <div className=" d-flex flex-column align-items-center">
              <div className="image">
                <img src={step1} />
              </div>

              <div className="d-flex justify-content-center flex-column sub-details-container">
                <div className="text-center">
                  <span className="text-black fs-2 fw-bolder">
                    Enter Amount
                  </span>
                </div>

                <div className="text-center">
                  <span className="sub-details ">
                    Enter the amount you wish to send
                  </span>
                </div>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center">
              <div className="image">
                <img src={step2} />
              </div>

              <div className="d-flex justify-content-center flex-column sub-details-container2">
                <div className="text-center">
                  <span className="text-black fs-2 fw-bolder">
                    SELECT RECIPIENT
                  </span>
                </div>

                <div className="text-center">
                  <span className="sub-details ">
                    Complete the recipient bank account details
                  </span>
                </div>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center">
              <div className="image">
                <img src={step3} />
              </div>

              <div className="d-flex justify-content-center flex-column sub-details-container3">
                <div className="text-center">
                  <span className="text-black fs-2 fw-bolder">
                    Confirm & Pay
                  </span>
                </div>

                <div className="text-center">
                  <span className="sub-details ">
                    Confirm payment details and send instantly to recipient
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span className="d-flex align-items-center steps-btn-container">
              <button className="started-btn" onClick={redirectLogin}>
                GET STARTED
              </button>
            </span>
          </div>
        </div>
      </div>

      {/* section three */}
      <div className="features-container min-h-500px" id="features">
        <div className="d-flex flex-column align-items-center">
          <div>
            <span className="title">FEATURES</span>
          </div>

          <div className=" d-flex sub-title-container align-itens-center justify-content-center  ">
            <span className="sub-title">BRINGING AND KEEPING YOU CLOSER</span>
          </div>

          <div className="feature-desc-container">
            <span className="feature-description">
              monibag products and services are designed to meet customer
              demands in an ever changing banking landscape
            </span>
          </div>

          <div className="features">
            <Carousel
              showArrows={true}
              showStatus={false}
              showThumbs={false}
              emulateTouch
            >
              {featureGroups.map((featureGroup, index) => {
                return (
                  <div key={index} className=" carousel-container">
                    {featureGroup.map((feature, featureIndex) => {
                      return (
                        <div
                          key={featureIndex}
                          className="feature-carousel d-lg-flex"
                        >
                          <div className="min-h-200px d-none d-lg-flex ">
                            <img
                              src={arrowPoint}
                              className="h-100 w-35px object-fit-cover"
                            />
                          </div>

                          <div>
                            <div className="feature-image">
                              <img
                                src={feature.image}
                                className="w-100 h-100 "
                              />
                            </div>

                            <div>
                              <span
                                className={`feature-title ${
                                  feature.active ? "" : "text-gray-400"
                                }`}
                              >
                                {feature.title}
                              </span>
                            </div>

                            <div>
                              <span className="feature-description">
                                {feature.description}
                              </span>
                            </div>

                            {!feature.active && (
                              <div className="mt-3">
                                <span className="feature-unavaliable">
                                  Coming Soon
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div className="d-flex">
            <div className="d-none d-lg-block fileBroImage">
              <img src={filesentBro} className="w-100 h-100" alt="" />
            </div>

            <div className="benefits-list-container">
              <div className="middle-sm ">
                <span className="benefits">Benefits</span>
              </div>

              <div className=" d-flex align-itens-center justify-content-center  ">
                <span className="sub-benefits">
                  ONE Platform, MANY BENEFITS
                </span>
              </div>

              <div className="my-3">
                {features.map((item, index) => {
                  return (
                    <div key={index} className="d-flex">
                      <div>
                        <img src={check} className="w-12px h-9px" alt="" />
                      </div>
                      <div className="ms-2">
                        <span className="text-gray-800 fs-base">{item}</span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div>
                <span className="d-flex align-items-center features-btn-container middle-sm ">
                  <button className="started-btn" onClick={redirectLogin}>
                    GET STARTED
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section four */}
      <div className="mission-container min-h-500px" id="mission">
        <div>
          <div>
            <span className="mission-title">OUR MISSION</span>
          </div>

          <div className="sub-mission-container">
            <span className="sub-mission">SAFE & SIMPLE WAY TO SEND MONEY</span>
          </div>

          <div className="mission-desc-container">
            <span className="mission-description">
              Our goal is to make money transfer simple and inexpensive by
              working with the world's most reputable and innovative financial
              institutions. Through strategic and tactical alliances, we provide
              a safe and reliable way to receive, send, and spend money across
              the world
            </span>
          </div>

          <div>
            <span className="d-flex align-items-center mission-btn-container">
              <button className="started-btn" onClick={redirectLogin}>
                GET STARTED
              </button>
            </span>
          </div>
        </div>

        <div className=" d-none d-lg-flex justify-content-between ">
          <div className=" d-flex flex-column align-items-center">
            <div className="image">
              <img src={mission1} className="w-100 h-100" />
            </div>

            <div className="image">
              <img src={mission2} className="w-100 h-100" />
            </div>
          </div>
          <div className=" d-flex flex-column justify-content-center align-items-center mission-image">
            <div className="image">
              <img src={mission3} className="w-100 h-100" />
            </div>

            <div className="image">
              <img src={mission4} className="w-100 h-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
