import React from "react";
import { Pin } from "../../../components";

const TransactionPin: React.FC = () => {
  return (
    <Pin
      proceed={() => console.log("hi bro")}
      header={"Create 4 Digit PIN"}
      message={"Create 4 Digit PIN"}
      canResend={false}
    />
  );
};

export default TransactionPin;
