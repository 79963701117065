import React, { useEffect, useState } from 'react';
import { minus, add } from '../../assets/images';
import { HashLink } from 'react-router-hash-link';

import { useNavigate } from 'react-router-dom';

const Terms: React.FC = () => {
  const navigate = useNavigate();
  const redirectSignup = () => {
    navigate('/signup');
  };

  const [sideActive, setSideActive] = useState('Contract');

  const onSetActive = (item: any) => {
    setSideActive(item);
  };

    React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="body-dashboard">
      {/* section one */}
      <div
        className=" d-flex justify-content-space between title-body"
        id="sendMoney"
      >
        <div className="title-body-text-2">
          <span className="header-title text-aling-header ">
            Terms & Conditions
          </span>

          <div className="feature-desc-container mt-9 mb-9">
            <span className="feature-description">
              Our terms and conditions outline how we do business with you, our
              valued customers. To learn more, please read through our terms and
              conditions.
            </span>
          </div>
        </div>
      </div>

      {/* section three */}
      <div className="features-container-3 min-h-500px">
        <div className="faq-con">
          <div className="faq-side">
            <HashLink to={'/terms/#Contract'}>
              <span
                className={sideActive === 'Contract' ? 'active' : ''}
                onClick={() => onSetActive('Contract')}
              >
                Contract Formation and Overview
              </span>
            </HashLink>
            <HashLink to={'/terms/#Definitions'}>
              <span
                className={sideActive === 'Definitions' ? 'active' : ''}
                onClick={() => onSetActive('Definitions')}
              >
                Definitions
              </span>
            </HashLink>
            <HashLink to={'/terms/#Obligations'}>
              <span
                className={sideActive === 'Obligations' ? 'active' : ''}
                onClick={() => onSetActive('Obligations')}
              >
                Our Obligations
              </span>
            </HashLink>
            <HashLink to={'/terms/#Refunds'}>
              <span
                className={sideActive === 'Refunds' ? 'active' : ''}
                onClick={() => onSetActive('Refunds')}
              >
                Cancellation and Refunds
              </span>
            </HashLink>
            <HashLink to={'/terms/#Information'}>
              <span
                className={sideActive === 'Information' ? 'active' : ''}
                onClick={() => onSetActive('Information')}
              >
                Collection of Information
              </span>
            </HashLink>
            <HashLink to={'/terms/#Property'}>
              <span
                className={sideActive === 'Property' ? 'active' : ''}
                onClick={() => onSetActive('Property')}
              >
                Intellectual Property
              </span>
            </HashLink>
            <HashLink to={'/terms/#Liability'}>
              <span
                className={sideActive === 'Liability' ? 'active' : ''}
                onClick={() => onSetActive('Liability')}
              >
                Warranties and Limitation of Liability
              </span>
            </HashLink>
            <HashLink to={'/terms/#Communications'}>
              <span
                className={sideActive === 'Communications' ? 'active' : ''}
                onClick={() => onSetActive('Communications')}
              >
                Electronic Communications
              </span>
            </HashLink>
            <HashLink to={'/terms/#Termination'}>
              <span
                className={sideActive === 'Termination' ? 'active' : ''}
                onClick={() => onSetActive('Termination')}
              >
                Termination
              </span>
            </HashLink>
            <HashLink to={'/terms/#Complaints'}>
              <span
                className={sideActive === 'Complaints' ? 'active' : ''}
                onClick={() => onSetActive('Complaints')}
              >
                Complaints
              </span>
            </HashLink>
            <HashLink to={'/terms/#Security'}>
              <span
                className={sideActive === 'Security' ? 'active' : ''}
                onClick={() => onSetActive('Security')}
              >
                Security
              </span>
            </HashLink>

            <HashLink to={'/terms/#General'}>
              <span
                className={sideActive === 'General' ? 'active' : ''}
                onClick={() => onSetActive('General')}
              >
                General
              </span>
            </HashLink>
          </div>
          <div className="faq-main">
            <div className="terms-body mb-9" id="Contract">
              <span className="faq-header  mb-9">Terms & Conditions</span>
            </div>

            <div className="terms-body" id="DataProtection">
              <span className="faq-header  mb-3">
                1. Contract Formation and Overview
              </span>
              <div className="terms-list mt-3">
                <span>
                  1.1. These Terms and Conditions govern the terms under which
                  you may access and use this website and the services
                  associated with it herein after referred to as, "Service". By
                  accessing, registering with and using the Service, you agree
                  to be bound by the terms and conditions hereinafter referred
                  to as “Terms and Conditions”. This Terms and Conditions is
                  written in English.
                  <br />
                  <br />
                  1.2. In these Terms and Conditions, the terms "Monibag”, "we",
                  "us", and "our" refer to Monibag (www.monibag.com), Zeus FX
                  Ltd together with its employees, directors, affiliates,
                  successors, and assigns. The company is registered in the
                  United Kingdom. It is Authorized and Regulated by the
                  Financial Conduct Authority (FCA) under the Payment Service
                  Regulations 2009 for the provision of payment services.
                  <br />
                  <br />
                  1.3. The terms "you" and "your" refer to users of the Service,
                  whether in their capacity as Senders, Recipients, or visitors
                  to this website.
                  <br />
                  <br />
                  1.4. These Terms and Conditions are effective from March 1,
                  2022. The Terms and Conditions may change from time to time,
                  but changes will only be effective from the date they are made
                  and will not change the terms on which you previously used the
                  Service.
                  <br />
                  <br />
                  1.4. These Terms and Conditions are effective from March 1,
                  2022. The Terms and Conditions may change from time to time,
                  but changes will only be effective from the date they are made
                  and will not change the terms on which you previously used the
                  Service.
                  <br />
                  <br />
                  1.5. The Service was created to provide remittance,
                  multi-currency and e-wallet functionalities to individuals and
                  businesses across the globe. For security reasons, we
                  recommend that you utilize the platform for genuine
                  transactions alone.
                  <br />
                  <br />
                  1.6. If there is any contradiction between these general
                  conditions and the terms and conditions of a specific service,
                  the terms and conditions of that specific service shall always
                  prevail.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Definitions">
              <span className="faq-header  mb-3">2. Definitions</span>
              <div className="mb-3 mt-5">
                <span className="faq-header  mb-3">
                  In this Terms and Conditions:
                </span>
              </div>
              <div className="terms-list">
                <span>
                  2.2. <b>Local Taxes</b> means any taxes or charges payable in
                  the Destination Country.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.3. <b>Payment Instrument </b>means a valid instrument of
                  payment such as a bank account, debit card or credit card.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.4. <b>Payout Amount </b>means the amount paid out to the
                  Recipient, after any foreign exchange conversion and excluding
                  Local Taxes.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.5. <b>Recipient</b> means someone who receives money through
                  the Service.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.6. <b>Sender</b> means someone who uses the Service to send
                  money.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.7. <b>Service Fee </b>means the fee plus any additional
                  charges applicable to each Transaction.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.8. <b>Service Provider</b> means a local bank, money
                  exchange house, or other third-party service providers in the
                  Destination Country with whom Monibag works with in providing
                  the Service.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.9. <b>Transaction</b> means a specific instruction to send
                  money through the Service.
                </span>
              </div>
              <div className="terms-list mt-5">
                <span>
                  2.7. <b>Transaction Amount</b> means the amount of money that
                  the Sender wishes to send to the Recipient, excluding any
                  applicable fees and prior to any foreign exchange conversion.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Obligations">
              <span className="faq-header  mb-3">3. Our Obligations</span>

              <div className="terms-list mt-3">
                <span>
                  3.1 Subject to these Terms and Conditions, we agree to provide
                  the Service to you using reasonable care. The Service may not
                  be available in whole or in part in certain regions,
                  countries, or jurisdictions.
                  <br />
                  <br />
                  3.2 We are not obliged to process any transaction. When you
                  submit a transaction, you are requesting that we process the
                  transaction on your behalf. We may, in our sole discretion,
                  choose whether or not to accept to process that transaction.
                  If we decide not to process the transaction, we will notify
                  you promptly of that decision and refund the money
                  accordingly.
                  <br />
                  <br />
                  3.3 Monibag reserves the right to modify or discontinue the
                  service or any part of the Service without notice, at any time
                  and from time to time. We may, in our absolute discretion,
                  decline any transaction amount to be transferred, either on a
                  per transaction basis or on an aggregate basis, and either on
                  individual accounts or on related accounts.
                  <br />
                  <br />
                  3.4 We may, in our sole discretion, decline transactions from
                  certain senders or to certain recipients, including but not
                  limited to entities and individuals on restricted or
                  prohibited lists issued from time to time by the UK
                  Government, the European Union, United States Department of
                  Treasury and the United Nations Security Council Committee. In
                  addition to the update list, not all Payment Instruments are
                  always available to all customers, and we may, in our sole
                  discretion, decline transactions funded from certain Payment
                  Instruments.
                  <br />
                  <br />
                  3.5 We will attempt to process transactions promptly, but a
                  transaction may be delayed or cancelled for a number of
                  reasons including but not limited to: our efforts to verify
                  your identity; to validate your transaction instructions; to
                  contact you; or otherwise to comply with applicable law; or
                  due to variations in business hours and currency availability.
                  <br />
                  <br />
                  3.6 We will attempt to provide senders and recipients with up
                  to date information regarding the list and location of our
                  Service Providers by means of information on our website.
                  However, you agree that Monibag shall not be held responsible
                  for any inaccuracies that may appear in that information or
                  any consequential loss which may result from incorrect or
                  incomplete information.
                  <br />
                  <br />
                  3.7 Monibag may, as necessary in providing the Service, store
                  all information required of a Recipient to prove his or her
                  identity or associated with their specific Transaction. Such
                  proofs may include a suitable form of valid identification
                  from a list of acceptable documents provided by the Service
                  Provider, and/or a Transaction tracking number, a personal
                  identification number (PIN), a "password", a "secret word", or
                  other similar identifiers.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Obligations">
              <span className="faq-header  mb-3">4. Your Obligations</span>

              <div className="mb-3 mt-5">
                <span className="faq-header  mb-3">You agree that:</span>
              </div>

              <div className="terms-list">
                <span>
                  4.1. You will not access, use or attempt to use the Service as
                  a Sender unless you are at least 18 years old, and that you
                  have the legal capacity to form a binding legal contract in
                  the relevant jurisdiction.
                  <br />
                  <br />
                  4.2. For each transaction that you initiate, the service fee
                  is debited alongside the transaction amount once the
                  transaction is submitted for processing. If you submit a
                  transaction that results in Monibag becoming liable for
                  charges including but not limited to chargebacks or other
                  fees, you agree to reimburse us for all such fees.
                  <br />
                  <br />
                  4.3. In connection with your registration and use of the
                  Service, you will:
                  <br />
                  <br />
                  4.3.1. provide us with true, accurate, current and complete
                  evidence of your identity, and promptly update your personal
                  information if and when it changes.
                  <br />
                  <br />
                  4.3.2. provide details of one or more Payment Instruments; and
                  <br />
                  <br />
                  4.3.3. provide us with true, accurate, current and complete
                  information for all transactions.
                  <br />
                  <br />
                  We do not accept any liability for damages resulting from
                  non-payment or delay in payment of a money transfer to a
                  Recipient or failure to perform a transaction under the
                  Service by reason of any of these matters.
                  <br />
                  <br />
                  4.4. When you pay for a transaction in one currency and the
                  Recipient is paid in another currency, there will be a
                  difference between the exchange rate at which we buy foreign
                  currency and the exchange rate provided to you. When you are
                  sending money under these Terms and Conditions, it is your
                  responsibility to make sure all the transaction details are
                  accurate before submission. Once a transaction has been
                  submitted for processing you will be unable to change any of
                  its details. You will be given the opportunity to confirm
                  transactions before submission and you must check the details
                  carefully.
                  <br />
                  <br />
                  4.5. Monibag will have no responsibility for any fees or
                  charges you may incur by the use of a particular Payment
                  Instrument to fund a Transaction. These may include but are
                  not limited to unauthorized overdraft fees imposed by banks if
                  there are insufficient funds in your bank account or "cash
                  advance" fees and additional interest which may be imposed by
                  credit card providers if they treat use of the Service as a
                  cash transaction rather than a purchase transaction.
                  <br />
                  <br />
                  4.6. You will only use the Service for genuine remittance,
                  multi-currency and e-wallet transactions. If Monibag
                  reasonably believes you are using the Service in a manner
                  different than the original intent of the platform, we reserve
                  the right to cancel your transaction(s).
                  <br />
                  <br />
                  4.7. You and the Recipients will only act on your own behalf.
                  You may not submit or receive a transaction on behalf of a
                  third party.
                  <br />
                  <br />
                  4.8. In using the Service, you will comply with these Terms
                  and Conditions as well as any applicable laws, rules or
                  regulations. It is a breach of these Terms and Conditions to
                  use the Service to send money (a) to a Recipient who has
                  violated the Terms and Conditions, or (b) in connection with
                  illegal activity including without limitation to money
                  laundering, fraud and the funding of terrorist organisations.
                  If you use the Service in connection with an illegal activity,
                  you will be reported to the appropriate legal authorities.
                  <br />
                  <br />
                  4.9. When using our website or the Service or when interacting
                  with Monibag you will not:
                  <br />
                  <br />
                  4.9.1. breach this Terms and Conditions, or any other
                  agreement between you and Monibag.
                  <br />
                  <br />
                  4.9.2. open more than one account, without our prior written
                  permission.
                  <br />
                  <br />
                  4.9.3. provide false, inaccurate, or misleading information.
                  <br />
                  <br />
                  4.9.4. allow anyone else access to your registration details
                  and will keep those details secure.
                  <br />
                  <br />
                  4.9.5. refuse to provide confirmation of any information you
                  provide to us, including proof of identity, or refuse to
                  cooperate in any investigation.
                  <br />
                  <br />
                  4.9.6. use an anonymous proxy (a tool that attempts to make
                  activity untraceable); or copy or monitor our website using
                  any robot, spider, or other automatic device or manual
                  process.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Refunds">
              <span className="faq-header  mb-3">
                5. Cancellation and Refunds
              </span>
              <div className="terms-list mt-3">
                <span>
                  5.1 The right to cancel a Transaction shall not be exercisable
                  in any of the following event; (a) where the recipient has
                  received value for the transaction, (b) where the recipient is
                  yet to receive value for the Transaction but, the transaction
                  is at a stage where a cancellation is no longer feasible and
                  (c) in any other circumstance where Monibag cannot proceed
                  with the recall of request for cancellation due to regulatory,
                  legal, compliance and for any other constraints or reasons for
                  that matter.
                  <br />
                  <br />
                  5.2 If you wish to exercise your right to cancel a Transaction
                  under this clause, you must submit a written request to our
                  support channel giving the Sender's username, together with
                  the Transaction tracking number, Transaction Amount, and the
                  reason for the request.
                  <br />
                  <br />
                  5.3 Any refunds will be credited back to the same Payment
                  Instrument used to fund the transaction and in the same
                  currency. No adjustment will be made for any currency
                  fluctuations which may have occurred in the meanwhile.
                </span>
              </div>
            </div>

          

            <div className="terms-body mt-9" id="Information">
              <span className="faq-header  mb-3">
                6. Collection of Information
              </span>
              <div className="terms-list mt-3">
                <span>
                  6.1 Customer Identification Program. - UK law, all European
                  countries and all other relevant jurisdictions to be requires
                  all financial institutions to assist in the fight against
                  money laundering activities and the funding of terrorism by
                  obtaining, verifying, and recording identifying information
                  about all customers. We may therefore require you to supply us
                  with personal identifying information and we may also legally
                  consult other sources to obtain information about you.
                  <br />
                  <br />
                  6.2 Verification and Checks. We will verify your residential
                  address and personal details in order to confirm your
                  identity. We may also pass your personal information to a
                  credit reference agency, which may keep a record of that
                  information. You can be rest assured that this is done only to
                  confirm your identity, that a credit check is not performed
                  and that your credit rating will be unaffected. All
                  information provided by you will be treated securely and
                  strictly in accordance with the Data Protection Act 1998.
                  <br />
                  <br />
                  6.3 By accepting these Terms and Conditions you authorize us
                  to make any inquiries we consider necessary to validate the
                  information that you provide to us. We may do this directly,
                  for example by asking you for additional information,
                  requiring you to take steps to confirm ownership of your
                  Payment Instruments or email address; or by verifying your
                  information against third party databases; or through other
                  sources.
                  <br />
                  <br />
                  6.4 Data Privacy Policy: You consent to our processing your
                  personal information for the purposes of providing the
                  Service, including for verification purposes as set out in
                  this clause. You also consent to the use of such data for
                  communicating with you, and for statutory, accounting and
                  archival purposes. You acknowledge that you have read and
                  consented to Monibag Data Privacy Policy.
                  <br />
                  <br />
                  6.5 Government Disclosures: We may be required by law to
                  provide information about you and your Transactions to
                  government or other competent authorities as described in our
                  Data Privacy Policy. You acknowledge and consent to our doing
                  this.
                  <br />
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Property">
              <span className="faq-header  mb-3">7. Intellectual Property</span>
              <div className="terms-list mt-3">
                <span>
                  7.1 The Monibag website and our associated Services, the
                  content, and all intellectual property relating to them and
                  contained in them (including but not limited to copyrights,
                  patents, database rights, trademarks and service marks) are
                  owned by us, our affiliates, or third parties. All rights,
                  title and interest in and to Monibag website and application
                  shall remain our property and/or the property of such other
                  third parties. <br />
                  <br />
                  7.2 The Monibag website and associated services may be used
                  only for the purposes permitted by these Terms and Conditions
                  or described on this website. You are authorized solely to
                  view the Monibag website for your own personal use. You may
                  not duplicate, publish, modify, create derivative works from,
                  participate in the transfer or sale of, post on the internet,
                  or in any way distribute or exploit the Monibag website, the
                  Monibag Service or any portion thereof for any public or
                  commercial use without our express written permission. You may
                  not: (a) use any robot, spider, scraper or other automated
                  device to access the Monibag website or the Monibag Service;
                  and/or (b) remove or alter any copyright, trademark or other
                  proprietary notice or legend displayed on the Monibag website
                  (or printed pages of the website). The name Monibag and other
                  names indicating ownership of Monibag products and/or services
                  referred to on the website are our exclusive marks or the
                  exclusive marks of our service providers. Other product,
                  service and company names appearing on the website may be the
                  trademarks of their respective owners. <br />
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Liability">
              <span className="faq-header  mb-3">
                8. Warranty Disclaimer and Limitation of Liability
              </span>
              <div className="terms-list mt-3">
                <span>
                  8.1 Monibag licenses the software "AS IS," and makes no
                  express or implied warranty of any kind. Monibag specifically
                  disclaims all indirect or implied warranties to the full
                  extent allowed by applicable law, including without limitation
                  all implied warranties of, non-infringement, merchantability,
                  title or fitness for any particular purpose. No oral or
                  written information or advice given by Monibag, its agents or
                  employees shall create a warranty.
                  <br />
                  <br />
                  8.2 To the fullest extent permitted by law, in no event will
                  Monibag, its Affiliates, its Officers, Employees, Agents,
                  Suppliers or Licensors be liable for: any indirect,
                  incidental, special, punitive, cover or consequential damages
                  (including, without limitation, damages for lost profits,
                  revenue, goodwill, use or content) however caused, under any
                  theory of liability, including, without limitation, contract,
                  tort, warranty, negligence or otherwise, even if Monibag has
                  been advised as to the possibility of such damages.
                  <br />
                  <br />
                  8.3 If a transaction is delayed or fails, you may have a right
                  to receive a refund or compensation under laws relating to the
                  provision of international money transfer/payment services. We
                  will provide you with the details of your rights to a refund
                  or compensation if you contact us via the support channels
                  specified on our website.
                  <br />
                  <br />
                  8.4 Any claim for compensation made by you and/or a Recipient
                  (who is not registered with us) must be supported by any
                  available relevant documentation.
                  <br />
                  <br />
                  8.5 If any loss which you or a Recipient (who is not
                  registered with us) suffers is not covered by a right to
                  payment under the laws referred to in clause 8.2, we will only
                  accept liability for that loss up to a limit which is the
                  greater of: (a) the amount of any service charge; and (b)
                  $200, unless otherwise agreed by us in writing. Our cap is on
                  a per transaction basis.
                  <br />
                  <br />
                  8.6 We do not, in any event, accept responsibility for:
                  <br />
                  <br />
                  8.6.1 any failure to properly execute your payment
                  instructions as a result of circumstances which could
                  reasonably be considered to be outside our control.
                  <br />
                  <br />
                  8.6.2 malfunctions in communication facilities which cannot
                  reasonably be considered to be under our control and that may
                  affect the accuracy or timeliness of messages you send to us.
                  <br />
                  <br />
                  8.6.3 any losses or delays in transmission of messages arising
                  out of the use of any internet service provider or caused by
                  any browser or other software which is not under our control.
                  <br />
                  <br />
                  8.6.4 errors on the website or with the Service caused by
                  incomplete or incorrect information provided to us by you or a
                  third party.
                  <br />
                  <br />
                  8.7 Where you are transacting with a Recipient who is not
                  registered with us, you agree to accept the provisions of this
                  clause not only for yourself, but also on behalf of the
                  Recipient.
                  <br />
                  <br />
                  8.8 Your relationship is with Monibag only. You agree that no
                  affiliate or agent of Monibag owes you any duty of care when
                  performing a task which would otherwise have to be performed
                  by Monibag under its agreement with you.
                  <br />
                  <br />
                  8.9 You agree to indemnify and hold harmless Monibag, our
                  subsidiaries, affiliates, officers, directors, employees,
                  agents, independent contractors, advertisers, partners, and
                  co-branders from all loss, damage, claims, actions or demands,
                  including reasonable legal fees, arising out of your use or
                  misuse of this website or Service from all activities that
                  occur under your account profile, your violation of this Terms
                  and Conditions or any other violation of the rights of another
                  person or party.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Communications">
              <span className="faq-header  mb-3">
                9. Electronic Communications
              </span>
              <div className="terms-list mt-3 ">
                <span>
                  9.1 You acknowledge that this Terms and Conditions shall be
                  entered into electronically, and that the following categories
                  of information ("Communications") may be provided by
                  electronic means:
                  <br />
                  <br />
                  9.1.1 This Terms and Conditions and any amendments,
                  modifications or supplements to it.
                  <br />
                  <br />
                  9.1.2 Your records of transactions through the Service.
                  <br />
                  <br />
                  9.1.3 Any initial, periodic or other disclosures or notices
                  provided in connection with the Service, including without
                  limitation those required by law.
                  <br />
                  <br />
                  9.1.4 Any customer service communication, including without
                  limitation, communication with respect to claims of error or
                  unauthorized use of the Service.
                  <br />
                  <br />
                  9.1.5 Any other communication related to Monibag and its
                  services.
                  <br />
                  <br />
                  9.2 You may however withdraw your consent to receive marketing
                  or promotional Communications electronically, but if you do,
                  access to certain benefits may be limited. <br />
                  <br />
                  9.3 In order to access and retain Communication, you must have
                  or have access to the following: <br />
                  <br />
                  9.3.1 An Internet browser that supports at least 128-bit
                  encryption, such as Internet Explorer version 4.0 or above.{' '}
                  <br />
                  <br />
                  9.3.2 An email account and email software capable of
                  interfacing with Monibag email servers. <br />
                  <br />
                  9.3.3 A personal computer, operating system and
                  telecommunications connections to the Internet capable of
                  supporting the foregoing. <br />
                  <br />
                  9.3.4 Sufficient electronic storage capacity on your
                  computer's hard drive or other data storage unit; and <br />
                  <br />
                  9.4 In addition, you must promptly update any change in your
                  email address on your Monibag profile.{' '}
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Termination">
              <span className="faq-header  mb-3">10. Termination</span>
              <div className="terms-list mt-3">
                <span>
                  10.1 Either party may terminate this Terms and Conditions on
                  one day's written notice.
                  <br />
                  <br />
                  10.2 We may terminate this Terms and Conditions with immediate
                  effect if you are in breach of any provisions of this Terms
                  and Conditions, Your use of the Service or the website is
                  disruptive to our other customers, or you do anything which in
                  our opinion is likely to bring us into disrepute.
                  <br />
                  <br />
                  10.3 Breaches or attempts to breach the security of the
                  website (including but not limited to: modifying or attempting
                  to modify any information; unauthorized logins, unauthorized
                  data access or deletion; interfering with the service, system,
                  host or network; reverse engineering of any kind; spamming;
                  hacking; falsifying data; introducing viruses, Trojan horses,
                  worms or other destructive or damaging programs or engines; or
                  testing security in any way) will also attract Termination
                  immediately.
                  <br />
                  <br />
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Complaints">
              <span className="faq-header  mb-3">11. Complaints</span>
              <div className="terms-list mt-3">
                <span>
                  11.1 If you wish to make a complaint about any aspect of the
                  Monibag service, please contact us via any of our support
                  channels on the website.
                  <br />
                  <br />
                  11.2 We will acknowledge receipt of your complaint within two
                  hours. We will review your complaint and revert accordingly
                  within twenty-four hours.
                  <br />
                  <br />
                  11.3 A full response or feedback is provided based on the
                  outcome of the findings. The response or feedback shall
                  include:
                  <br />
                  <br />
                  · the remedy or the resolution put in place
                  <br />
                  <br />
                  · any options for review that may be available to the
                  complainant. Such as an internal review, external review or
                  appeal with relevant escalation contacts.
                  <br />
                  <br />
                  11.4 We will acknowledge receipt of your complaint within two
                  hours. We will review your complaint and revert accordingly
                  within twenty-four hours.
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="Security">
              <span className="faq-header  mb-3">12. Security</span>
              <div className="terms-list mt-3">
                <span>
                  We take security very seriously at Monibag, and we work hard,
                  using state of the art security measures to ensure that your
                  information remains secured. <br />
                  <br />
                  Monibag Service is a safe and convenient way to send money to
                  friends and family as well as carry out other electronic
                  transactions to people that you trust. However, we do advise
                  you to consider very carefully before sending money to anyone
                  that is not well known to you. You should be very cautious of
                  deals or offers that seem too good to be true, they may be
                  scams. If you are aware of anyone or any entity that is using
                  the Service inappropriately, please email us at
                  support@monibag.com. Similarly, if you receive any emails,
                  purporting to be from Monibag, which you suspect may be
                  "phishing" (fake) emails, please forward them to
                  support@monibag.com. <br />
                  <br />
                  For questions and other enquiries, please email us at
                  hello@monibag.com or connect with us via our listed social
                  media handles. <br />
                </span>
              </div>
            </div>

            <div className="terms-body mt-9" id="General">
              <span className="faq-header  mb-3">13. General</span>
              <div className="terms-list mt-3">
                <span>
                  13.1 <b>Governing law:</b> This Agreement will be governed by
                  English law and the parties submit to the exclusive
                  jurisdiction of the English Courts.
                  <br />
                  <br />
                  13.2 <b>No Waiver:</b> The failure of Monibag to exercise or
                  enforce any right or provision of the Terms and Conditions
                  shall not constitute a waiver of such right or provision.
                  <br />
                  <br />
                  13.3 <b>Modification: </b>We may modify this Terms and
                  Conditions from time to time without notice to you, except as
                  may be required by law. You can review the most current
                  version of the Terms and Conditions at any time by visiting
                  this website. If you use the Service after the effective date
                  of an amendment or modification, you shall be deemed to have
                  accepted that amendment or modification.
                  <br />
                  <br />
                  13.4 <b>Entire Agreement: </b>This agreement constitutes the
                  entire agreement between the parties and supersedes all prior
                  understandings or agreements relating to the subject matter of
                  this agreement.
                  <br />
                  <br />
                  13.5 <b>Severability: </b> If any provision of the Terms and
                  Conditions is found by an arbitrator or a court of competent
                  jurisdiction to be invalid, the parties nevertheless agree
                  that the arbitrator or court should endeavour to give
                  appropriately valid effect to the intention of the Terms and
                  Conditions as reflected in the provision, and the other
                  provisions of the Terms and Conditions shall remain in full
                  force and effect.
                  <br />
                  <br />
                  13.6 Any external links to third party websites on the website
                  are provided as a convenience to you. These sites are not
                  controlled by us in any way, and we are not responsible for
                  the accuracy, completeness, legality or any other aspect of
                  these other sites including any content provided on them. You
                  access such websites at your own risk.
                  <br />
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
