import { Checkbox, Form, Input } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SubmitButton } from "../../../components";
import { appleSvg, googleSvg, passwordLockSvg } from "./components/usefulSvg";
import { checkPassword } from "../../../utils";
import { useRequestSignup } from "../useAuth";
import { Session } from "inspector";
import { backIcon } from "../../../assets/images";

const Register: React.FC = () => {
  const { data, error, mutate, isLoading } = useRequestSignup();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    // console.log("Success:", values);
    let payload: any = {
        email: values.email,
        password: values.password,
        confirm_password: values.password,
    }
      
// Convert the object to a JSON string
let payloadString = JSON.stringify(payload);

// Store the JSON string in sessionStorage
    sessionStorage.setItem('register', payloadString);
    mutate(
      {
        email: values.email,
        password: values.password,
        confirm_password: values.password,
      },
      {
        onSuccess: (res: any) => {
          sessionStorage.setItem("email", values.email);

          navigate("/otpVerify");
        },
        onSettled: () => {},
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const goBack = () => {
    navigate(-1);
  };

  const [form] = Form.useForm();

  return (
    <div className="main-container p-7 mt-5">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <img
            src={backIcon}
            alt=""
            className="w-30px h-30px"
            onClick={() => goBack()}
          />

          <div className="d-flex justify-content-end mt-5">
            <span className="acct-before">
              Already have an account?
              <br />{" "}
              <Link to={"/"} className="signIn">
                {" "}
                Sign In
              </Link>{" "}
            </span>
          </div>
        </div>
        <div className="my-6 ms-3r">
          <span className="fs-2hx fw-bolder text-dblack ">Sign Up</span>
        </div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "The input is not valid E-mail!" },
            ]}
          >
            <Input
              placeholder={"Enter Email Address"}
              prefix={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5776 6.54175C13.5776 6.54175 10.9025 9.75227 8.94598 9.75227C6.99025 9.75227 4.28516 6.54175 4.28516 6.54175"
                    stroke="#A4A4A6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 8.99991C1 3.3015 2.98413 1.40259 8.93651 1.40259C14.8889 1.40259 16.873 3.3015 16.873 8.99991C16.873 14.6975 14.8889 16.5972 8.93651 16.5972C2.98413 16.5972 1 14.6975 1 8.99991Z"
                    stroke="#A4A4A6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("password").length === 0) {
                    return Promise.resolve();
                  }
                  if (checkPassword(getFieldValue("password"))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.!"
                    )
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder={"Enter Password"}
              type="password"
              prefix={passwordLockSvg}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("password does not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={"Enter Password"}
              prefix={
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5776 6.54175C13.5776 6.54175 10.9025 9.75227 8.94598 9.75227C6.99025 9.75227 4.28516 6.54175 4.28516 6.54175"
                    stroke="#A4A4A6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 8.99991C1 3.3015 2.98413 1.40259 8.93651 1.40259C14.8889 1.40259 16.873 3.3015 16.873 8.99991C16.873 14.6975 14.8889 16.5972 8.93651 16.5972C2.98413 16.5972 1 14.6975 1 8.99991Z"
                    stroke="#A4A4A6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("")),
              },
            ]}
          >
            <Checkbox className="custom-checkbox">
              <span className="pop text-form-grey fs-10">
                By continuing you agree to our
              <Link to={'/terms'} style={{textDecoration: 'none'}}><span className="text-tc"> Terms & Conditions </span></Link>  
                and   <Link to={'/privacy'} style={{textDecoration: 'none'}}><span className="text-tc"> Privacy Policy.</span></Link> 
              </span>
            </Checkbox>
          </Form.Item>

          <Form.Item className="d-flex justify-content-center">
            <SubmitButton form={form} isLoading={isLoading} />
          </Form.Item>
        </Form>
        <div className="alt-signUp">
          <div className="line" />
          <div>
            <span className="text">or with</span>
          </div>
          <div className="line" />
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4 ">
          <div>{appleSvg}</div>

          <div>{googleSvg}</div>
        </div>
      </div>
    </div>
  );
};

export default Register;
