import React, { useState } from 'react';

// import './style.css';
import { Button, Form, Input } from 'antd';
import { SubmitButton } from '../../../../components';
import TextArea from 'antd/es/input/TextArea';

interface Step1Props {
  handleNextStep: () => void;
}

export const StepThree: React.FC<Step1Props> = ({ handleNextStep }) => {
  const [form] = Form.useForm();

  const [isLoading, setisLoading] = useState(false);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    handleNextStep();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validateEmailForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {}
  };

  const next = () => {
    return (
      <div>
        <span>Save And Continue</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.56224 13.4062H17.7466L14.3646 9.11719C14.2685 8.99531 14.3552 8.8125 14.5122 8.8125H16.031C16.2607 8.8125 16.4787 8.91797 16.6193 9.09844L20.463 13.9734C20.8497 14.4656 20.5005 15.1875 19.8747 15.1875H3.56224C3.45912 15.1875 3.37474 15.1031 3.37474 15V13.5938C3.37474 13.4906 3.45912 13.4062 3.56224 13.4062Z"
            fill="#FEF8E6"
          />
        </svg>
      </div>
    );
  };
  return (
    <Form
      form={form}
      name="signInForm"
      initialValues={{
        email: '',
        password: '',
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="horizontal"
    >
      <div className="stepThree">
        <div className="stepThree-wrapper">
          <div className="div">
            <div className="div-2">
              <div className="group-wrapper">
                <div className="group-2">
                  <div className="group-3">
                    <div className="text-wrapper-2">Ownership Structure</div>
                  </div>
                </div>
              </div>
              <p className="please-provide-full">
                Please Provide Full Details Of Any Natural Or Legal Persons,
                Partnership Or Trust Holding 5% Or More Of The Voting Rights:
              </p>
            </div>

            <div className="stepThree-wrapper-2">
              <div className="div-3">
                <div className="stepThree-wrapper-3">
                  <div className="div-4">
                    <div className="div-5">
                      <div className="div-6">
                        <div className="text-wrapper-3">
                          Name Of Shareholder
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div >
                            <div className="text-wrapper-4">
                                      <Form.Item
                              name="NameShareholder"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your  Name Of Shareholder!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter  Name Of Shareholder'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-6">
                        <div className="text-wrapper-3">
                          Percentage Holding (%)
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div className="text-wrapper-4">
                                   <Form.Item
                              name="PercentageHolding"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your  Percentage Holding!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter  Percentage Holding'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="div-6">
                        <div className="text-wrapper-3">
                          Means Of Identification
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div className="text-wrapper-4">
                                   <Form.Item
                              name="MeansIdentification"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Means Of Identification!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Means Of Identification'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-6">
                        <div className="text-wrapper-3">Id Number</div>
                        <div>
                          <div className="div-wrapper-2">
                             <Form.Item
                              name="IdNumber"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Id Number!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Id Number'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-6">
                      <div className="text-wrapper-3">Pep (yes Or No)</div>
                      <div className="stepThree-wrapper-4">
                        <div>
                          <div className="div-7">
                            <div className="text-wrapper-4">
                                 <Form.Item
                              name="Pep"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Pep!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Pep'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stepThree-wrapper-5">
                  <div className="div-wrapper-3">
                    <div className="text-wrapper-5">Add More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="line"
          src="https://c.animaapp.com/sgzvJIUq/img/line-26.svg"
          alt=''
        />
        <div className="stepThree-wrapper">
          <div className="div">
            <div className="div-2">
              <div className="group-wrapper">
                <div className="group-4">
                  <div className="group-5">
                    <div className="text-wrapper-2">
                      Directors and Management Staff
                    </div>
                  </div>
                </div>
              </div>
              <p className="please-provide-full">
                Please Provide Full Details Of Any Natural Or Legal Persons,
                Partnership Or Trust Holding 5% Or More Of The Voting Rights:
              </p>
            </div>
            <div className="stepThree-wrapper-2">
              <div className="div-3">
                <div className="stepThree-wrapper-3">
                  <div className="div-4">
                    <div className="div-5">
                      <div className="div-6">
                        <div className="text-wrapper-3">Name</div>
                        <div className="stepThree-wrapper-4">
                          <div >
                                      <Form.Item
                              name="DirectorsName"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors Name!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors Name'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="stepThree-wrapper-4">
                        <div className="div-6">
                          <div className="text-wrapper-3">Position</div>
                          <div>
                                 <Form.Item
                              name="DirectorsPosition"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors Position!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors Position'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="div-6">
                        <div className="text-wrapper-3">
                          Means Of Identification
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div>
                            <Form.Item
                              name="DirectorsMeansIdentification"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors Means Of Identification!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors Means Of Identification'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                     <div className="div-6">
                        <div className="text-wrapper-3">
                         ID Number
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div>
                            <Form.Item
                              name="DirectorsIDNumber"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors ID Numberon!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors ID Number'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        <div className="div-5">
                      <div className="div-6">
                        <div className="text-wrapper-3">
                          Nationality
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div>
                            <Form.Item
                              name="DirectorsNationality"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors Nationality!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors Nationality'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div className="div-6">
                        <div className="text-wrapper-3">
                        PEP (Yes or NO)
                        </div>
                        <div className="stepThree-wrapper-4">
                          <div>
                            <div>
                            <Form.Item
                              name="DirectorsPEP"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please input your Directors PEP (Yes or NO)!',
                                },
                              ]}
                            >
                              <Input
                                placeholder={'Enter Directors PEP (Yes or NO)'}
                              className="div-wrapper-2"
                                onBlur={validateEmailForm}
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stepThree-wrapper-6">
                  <div className="div-wrapper-3">
                    <div className="text-wrapper-5">Add More</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-9">
              <div className="div-wrapper-4">
                <div className="text-wrapper-6">Back</div>
              </div>
                   <Form.Item>
                    <SubmitButton
                      form={form}
                      submitProp={next()}
                    />
                  </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
