import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/react-query/queryClient';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRouter />
        <ToastContainer />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
